/**
 * Created by jimmy on 2022/6/1.
 */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {Result, Button, Steps, Input, Form, Radio, message} from 'antd';
import {ExclamationCircleFilled} from '@ant-design/icons';

import * as REQUEST from "../../common/request";
import * as CONST from "../../common/const";

import Page from '../../components/page';
import Step0 from './components/step0';
import Step1 from './components/step1';
import Step2 from './components/step2';
import Step3 from './components/step3';
import Step4 from './components/step4';
import './index.less';
import moment from "moment";
import * as Utils from "../../common/utils";
import * as config from "../../common/config";
import ListFormItem from "../../components/list/listFormItem";

const PageEnterpriseCertificationModel = props => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [ step, setStep ] = useState(0);

  const [ enterprise, setEnterprise ] = useState(null);
  const getEnterprise = async () => {
    const result = await REQUEST.get({
      url: '/enterprise/info/get'
    });
    if(!result) return;

    const enterprise = result.data;
    if(enterprise?.birthday) enterprise.birthday = moment(enterprise.birthday)
    if(enterprise?.agentBirthday) enterprise.agentBirthday = moment(enterprise.agentBirthday)
    if(enterprise?.agentCertificationPeriod?.length > 0) {
      const agentCertificationPeriod = []
      for(let i = 0, len = enterprise.agentCertificationPeriod.length; i < len; i++) {
        agentCertificationPeriod.push(moment(enterprise.agentCertificationPeriod[i]));
      }
      enterprise.agentCertificationPeriod = agentCertificationPeriod;
    }
    if(enterprise?.agentCertificationPeriod?.length > 0) {
      const agentCertificationPeriod = []
      for(let i = 0, len = enterprise.agentCertificationPeriod.length; i < len; i++) {
        agentCertificationPeriod.push(moment(enterprise.certificationPeriod[i]));
      }
      enterprise.certificationPeriod = agentCertificationPeriod;
    }
    if(enterprise?.agentCertificationPeriod?.length > 0) {
      const agentCertificationPeriod = [];
      for(let i = 0, len = enterprise.operationPeriod.length; i < len; i++) {
        agentCertificationPeriod.push(moment(enterprise.operationPeriod[i]));
      }
      enterprise.operationPeriod = agentCertificationPeriod;
    }

    setEnterprise(enterprise);
    form.setFieldsValue(enterprise);
console.log('enterprise', enterprise)
    if(enterprise?.certificationStatus == CONST.ENTERPRISE_CERTIFICATION_STATUS_REVIEWING || enterprise?.certificationStatus == CONST.ENTERPRISE_CERTIFICATION_STATUS_REREVIEWING) {
      setStep(4);
    }
  };
  useEffect(() => {
    getEnterprise();
  }, [ 123 ]);

  const submitHandle = async (data) => {
    if(enterprise?.id) {
      data.id = enterprise.id;
    }
    const result = await REQUEST.post({
      url: '/enterprise/info/set',
      data
    });
    if(!result) return;
    message.success('操作成功');
    // setStep(4);
    getEnterprise();
  };

  return (
    <Page {...{
      rawHeader: true,
      needLogin: false,
      title: '企业认证',
    }}>
      <section className="enterprise-certification-container">
        {
          enterprise?.certificationStatus == CONST.ENTERPRISE_CERTIFICATION_STATUS_REJECTED &&
          <div className="enterprise-certification-reason">
            <ExclamationCircleFilled style={{ paddingRight: 8, color: '#D54941' }} />
            <span>{enterprise?.certificationResultReason}</span>
          </div>
        }

        <Steps {...{
          items: [
            { title: '身份验证', disabled: true },
            { title: '编辑主体信息', disabled: true },
            { title: '编辑法人信息', disabled: true },
            { title: '编辑经营信息', disabled: true },
            { title: '审核与公示', disabled: true },
          ],
          current: step,
          onChange: setStep,
          type: 'navigation',
          size: 'small'
        }} />

        <div className="enterprise-certification-step-content">
          <Form {...{
            form,
            initialValues: enterprise || undefined,
            layout: step != 0 ? 'horizontal' : 'vertical',
            labelCol: step != 0 && { span: 6 } || {},
            wrapperCol: step != 0 && { offset: 0, span: 18 } || {},
            onFinish: values => {
              if(values.agentBirthday) {
                values.agentBirthday = moment(values.agentBirthday).format('YYYY-MM-DD')
              }
              if(values?.agentCertificationPeriod?.length > 0) {
                const agentCertificationPeriod = []
                for(let i = 0, len = values.agentCertificationPeriod.length; i < len; i++) {
                  agentCertificationPeriod.push(moment(values.agentCertificationPeriod[i]).format('YYYY-MM-DD'));
                }
                values.agentCertificationPeriod = agentCertificationPeriod;
              }
              if(values.birthday) {
                values.birthday = moment(values.birthday).format('YYYY-MM-DD')
              }
              if(values?.certificationPeriod?.length > 0) {
                const certificationPeriod = [];
                for(let i = 0, len = values.certificationPeriod.length; i < len; i++) {
                  certificationPeriod.push(moment(values.certificationPeriod[i]).format('YYYY-MM-DD'));
                }
                values.certificationPeriod = certificationPeriod;
              }
              if(values?.operationPeriod?.length > 0) {
                const operationPeriod = [];
                for(let i = 0, len = values.operationPeriod.length; i < len; i++) {
                  operationPeriod.push(moment(values.operationPeriod[i]).format('YYYY-MM-DD'));
                }
                values.operationPeriod = operationPeriod
              }
              submitHandle(values);
            }
          }}>
            <Step0 {...{
              form,
              style: { display: step == 0 ? 'block' : 'none' },
              handleChange: (type) => {
                setStep(step + type)
              }
            }} />
            <Step1 {...{
              form,
              data: enterprise,
              style: { display: step == 1 ? 'block' : 'none' },
              updateDataHandle: (data) => {
                setEnterprise({ ...enterprise, ...data });
              },
              handleChange: (type) => {
                setStep(step + type)
              }
            }} />
            <Step2 {...{
              form,
              data: enterprise,
              style: { display: step == 2 ? 'block' : 'none' },
              updateDataHandle: (data) => {
                setEnterprise({ ...enterprise, ...data });
              },
              handleChange: (type) => {
                setStep(step + type)
              }
            }} />
            <Step3 {...{
              form,
              data: enterprise,
              style: { display: step == 3 ? 'block' : 'none' },
              updateDataHandle: (data) => {
                setEnterprise({ ...enterprise, ...data });
              },
              handleChange: (type) => {
                setStep(step + type)
              }
            }} />
            <Step4 {...{
              form,
              style: { display: step == 4 ? 'block' : 'none' },
            }} />
          </Form>
        </div>
      </section>
    </Page>
  );
};

export default PageEnterpriseCertificationModel;
