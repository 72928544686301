/**
 * Created by jimmy on 2022/6/1.
 */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {Result, Button, Steps, Input, Form, Radio, message, Col, Divider,} from 'antd';

import * as REQUEST from "../../../common/request";
import * as CONST from "../../../common/const";
import * as Utils from "../../../common/utils";

import Page from '../../../components/page';
import '../index.less';
import * as config from "../../../common/config";
import ListFormItem from "../../../components/list/listFormItem";
import {CERTIFICATION_TYPE_LABEL, ENTERPRISE_REAL_OPERATOR_LEGAL} from "../../../common/const";

const CompCertStep0Model = props => {
  const history = useHistory();

  return (
    <div style={props.style}>
      <Divider orientation="left">联系人信息</Divider>
      {
        [
          { required: true, label: '联系人姓名', name: 'contactName', placeholder: '请输入', type: 'input', extra: '请填写公司运营负责人' },
          { required: true, label: '手机号', name: 'contactMobile', placeholder: '请输入', type: 'input', extra: '非常重要，将用于接收日常经营提醒及相关操作验证' },
          { required: true, label: '常用邮箱', name: 'contactEmail', placeholder: '请输入', type: 'input', extra: '非常重要，将接收认证及风控相关重要通知' },
          { required: true, label: '客服电话', name: 'contactPhone', placeholder: '请输入', type: 'input', extra: '请注意填写格式，举例（座机：0755-88880000；手机：13688880000；400电话：4008880000）' },
        ].map((_item, _idx) => {
          return (
            <ListFormItem item={_item} style={{ padding: 0 }} />
          )
        })
      }
      <Divider orientation="left">开票信息</Divider>
      {
        [
          { required: true, label: '发票抬头', name: 'invoiceTitle', placeholder: '请输入', type: 'input' },
          { required: true, label: '纳税人登记号', name: 'invoiceFax', placeholder: '请输入', type: 'input' },
          { required: true, label: '手机号', name: 'invoiceMobile', placeholder: '请输入', type: 'input' },
          { required: true, label: '电子邮箱', name: 'invoiceEmail', placeholder: '请选择', type: 'input' },
          { required: true, label: '基本户开户银行', name: 'invoiceBankName', placeholder: '请选择', type: 'input' },
          { required: true, label: '基本户开户账户', name: 'invoiceBankAccount', placeholder: '请选择', type: 'input' },
          { required: true, label: '企业注册地址', name: 'invoiceBusinessAddress', placeholder: '请选择', type: 'input' },
          { required: true, label: '企业注册电话', name: 'invoiceBusinessPhone', placeholder: '请选择', type: 'input' },
          { label: '备注信息', name: 'invoiceExtra', placeholder: '请输入', type: 'textarea' },
        ].map((_item, _idx) => {
          return (
            <ListFormItem item={_item} style={{ padding: 0 }} />
          )
        })
      }

      <Form.Item label=" " colon={false}>
        <Button style={{ marginRight: 16 }} onClick={() => {
          if(typeof props?.handleChange == 'function') {
            props.handleChange(-1);
          }
        }}>上一步</Button>
        <Button type="primary" htmlType="submit">提交</Button>
      </Form.Item>
    </div>
  );
};

export default CompCertStep0Model;
