/**
 * Created by jimmy on 2022/6/1.
 */

import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {Tabs, Form, Input, Space, Button, Divider, message, Popover, Popconfirm} from 'antd';
import copyText from 'copy-text-to-clipboard';

import * as REQUEST from "../../common/request";
import * as Utils from "../../common/utils";
import * as config from "../../common/config";
import * as CONST from "../../common/const";
import Page from '../../components/page';
import FButton from '../../components/fbutton';

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper';
import "swiper/swiper.less";

import "./index.less";


import IMG_BANNER_COVER from './images/banner-cover.png';
import IMG_INTRO_1 from './images/intro1.jpg';
import IMG_ICON_CLOSE from './images/close.png';
import IMG_ICON_GOU_WHITE from './images/gou-white.png';
import IMG_ISLOGAN_BG from './images/islogan-bg.jpg';
import IMG_SIGN_70 from './images/70-sign.png';
import IMG_PSIGN_70 from './images/70p-sign.png';
import IMG_SIGN_80 from './images/80-sign.png';
import IMG_PSIGN_80 from './images/80p-sign.png';
import IMG_SIGN_90 from './images/90-sign.png';
import IMG_PSIGN_90 from './images/90p-sign.png';

import IMG_FLOW_1 from './images/flow1.png';
import IMG_FLOW_2 from './images/flow2.png';
import IMG_CASE_1 from './images/case1.png';
import IMG_CASE_2 from './images/case2.png';
import IMG_CASE_3 from './images/case3.png';
import IMG_CASE_4 from './images/case4.png';
import IMG_CASE_5 from './images/case5.png';
import IMG_CASE_6 from './images/case6.png';
import IMG_CASE_7 from './images/case7.png';
import IMG_CASE_8 from './images/case8.png';

const PageHomeModel = props => {
  const history = useHistory();

  const [ userInfo, setUserInfo ] = useState(null);
  const getUserInfo = async () => {
    const result = await REQUEST.get({
      url: '/account/get',
      keepStatus: true
    });
    if(!result) return;
    setUserInfo(result.data || {});
  };
  useEffect(() => {
    getUserInfo();

    return () => false;
  }, []);

  const toAppHandle = () => {
    if(userInfo?.id) {
      history.push('/apps');
    }else{
      history.push('/login');
    }
  };

  return (
    <Page title="首页">
      <section className="home-container">
        <section className="ibanner-wrap">
          <div className="main-container ibanner">
            <div className="ibanner-item">
              <div className="ibanner-message">
                <span className="large">人人可以搭建移动应用</span>
                <span>移动应用无代码搭建平台，无需一行代码搭建属于你的移动应用。</span>
                <div className="ibanner-button">
                  {/*<FButton type="ghost" size="medium" style={{ marginRight: window.pxToRem(24) }}>立即咨询</FButton>*/}
                  <FButton size="large" onClick={toAppHandle}>极速搭建专属应用</FButton>
                </div>
              </div>
              <div className="ibanner-cover"><img src={IMG_BANNER_COVER} /></div>
            </div>
          </div>
        </section>

        <section className="info-wrap">
          <div className="info-header-wrap main-container">
            <span className="main">微信小程序搭建 轻而易举</span>
            <span>自由拖拽创建小程序页面框架，设置跳转页面、关联小程序APPid并发布，两步操作即可让您拥有自己的小程序。</span>
          </div>
          <div className="info-body-wrap">
            <Swiper {...{
              modules: [ Autoplay ],
              initialSlide: 1,
              slidesOffsetBefore: (window.innerWidth - 1180/1920 * window.innerWidth)/2,
              width: 1180/1920 * window.innerWidth,
              spaceBetween: 1180/1920 * 49,
              loopedSlides: 2,
              loop: true,
              autoplay: {
                delay: 3500,
                stopOnLastSlide: false,
                disableOnInteraction: true
              }
            }}>
              {
                [ IMG_FLOW_1, IMG_FLOW_2, IMG_FLOW_1, IMG_FLOW_2 ].map((item, idx) => {
                  return <SwiperSlide key={idx}>
                    <div className="intro-banner-item" style={{ backgroundImage: `url(${item})` }} />
                  </SwiperSlide>
                })
              }
            </Swiper>
          </div>
        </section>

        <section className="info-wrap">
          <div className="info-header-wrap main-container">
            <span className="main">什么是无代码搭建？</span>
            <span>无代码开发是一种无需代码基础，适合业务人员使用的移动应用搭建平台，它可以快速构建小程序和H5应用，适应各种需求变化，用户在几分钟内就能完成小程序的开发与部署，并能够随时调整和更新。</span>
          </div>
          <div className="info-body-wrap">
            <div className="main-container info2-block-wrap">
              <div className="info2-block info2-block-a">
                <span className="title">定制开发</span>
                <div className="desc-list">
                  {
                    [
                      { label: '开发周期', text: '周期按月计、IT开发人员编写代码完成开发，涉及评估、排期、测试、上线等较多环节。' },
                      { label: '业务适配', text: '适配程度高，但开发结果依赖IT人员对需求的理解程度，且难以随业务变化快速调整。' },
                      { label: '开发成本', text: '成本极高，需要投入大量IT开发人力。' },
                      { label: '迭代效率', text: '不敏捷，每个新需求都要重新走研发流程。' },
                      { label: '部署运维', text: '需要自主运维，仅支持本地部署。' },
                    ].map((item, idx) => {
                      return (
                        <div className="desc-item" key={idx}>
                          <label>{item.label}</label>
                          <span>{item.text}</span>
                          <em style={{ backgroundImage: `url(${IMG_ICON_CLOSE})` }} />
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              <div className="info2-block info2-block-b">
                <span className="title">无代码搭建</span>
                <div className="desc-list">
                  {
                    [
                      { text: '周期按小时计。“拖拉拽”方式完成开发，无需编程，时间缩短90%以上。' },
                      { text: '适配程度高，业务人员也能开发搭建，满足各类人群的个性化需求，可随业务变化敏捷调整。' },
                      { text: '成本低，拥有一个搭建系统相当于拥有多套小程序。' },
                      { text: '敏捷高效，随时调整、随时发布使用。' },
                      { text: '平台统一运维，无需自主运维部署。' },
                    ].map((item, idx) => {
                      return (
                        <div className="desc-item" key={idx}>
                          <span>{item.text}</span>
                          <em style={{ backgroundImage: `url(${IMG_ICON_GOU_WHITE})` }} />
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="info-wrap">
          <div className="info-header-wrap main-container">
            <span className="main">无代码搭建可以实现</span>
          </div>
          <div className="info-body-wrap">
            <div className="main-container info3-block-wrap">
              {
                [
                  {
                    number: 70, sign: IMG_SIGN_70, psign: IMG_PSIGN_70, title: '降低搭建成本', desc: '专业的IT团队人力成本高，使用我们的小程序无代码搭建系统，可以最大限度降低开发成本，不会代码人员也可以搭建出小程序，实现数字化效益最大化。',
                    numStyle: { color: '#03BE79' },
                  },
                  {
                    number: 90, sign: IMG_SIGN_90, psign: IMG_PSIGN_90, title: '缩短开发周期', desc: '传统开发的瀑布式开发流程耗时长，而无代码将其抽象成标准化的模块，通过拖拉拽的方式敏捷搭建系统，极大缩短开发时间。',
                    numStyle: { color: '#248BFB' },},
                  {
                    number: 80, sign: IMG_SIGN_80, psign: IMG_PSIGN_80, title: '提升个性化需求满足度', desc: '将管理者/业务人员的业务理念快速落地成为数字化工具，验证业务模式的可行性，提高投入产出比，带来实际效益。',
                    numStyle: { color: '#9F65FC' },
                  },
                ].map((item, idx) => {
                  return <div className="info3-block-item" key={idx}>
                    <div className="number-wrap" style={item.numStyle}>
                      <span className="number">{item.number}</span>
                      <img className="sign" src={item.sign} />
                      <img className="p-sign" src={item.psign} />
                    </div>
                    <span className="title">{item.title}</span>
                    <span className="desc">{item.desc}</span>
                  </div>
                })
              }
            </div>
          </div>
        </section>

        <section className="info-wrap">
          <div className="info-header-wrap main-container">
            <span className="main">他们正在通过搭建应用实现业绩持续增长</span>
          </div>
          <div className="info-body-wrap">
            {
              [
                { speed: 5000, reverseDirection: true, list: [ IMG_CASE_1, IMG_CASE_2, IMG_CASE_3, IMG_CASE_4, IMG_CASE_5, IMG_CASE_6, IMG_CASE_7, IMG_CASE_8 ] },
                // { speed: 3500, reverseDirection: false, list: [ IMG_CASE_5, IMG_CASE_6, IMG_CASE_7, IMG_CASE_8 ] },
                // { speed: 6500, list: [ IMG_CASE_1, IMG_CASE_2, IMG_CASE_3, IMG_CASE_4, IMG_CASE_5 ] },
              ].map((item, idx) => {
                return (
                  <div className="case-show-block" key={idx}>
                    <Swiper {...{
                      modules: [ Autoplay ],
                      width: 580/1920 * window.innerWidth,
                      spaceBetween: 580/1920 * 24,
                      loop: true,
                      loopAdditionalSlides: 2,
                      speed: item.speed,
                      autoplay: {
                        delay: 0,
                        stopOnLastSlide: false,
                        reverseDirection: item.reverseDirection
                      }
                    }}>
                      {
                        item.list.map((item, idx) => {
                          return <SwiperSlide key={idx}>
                            <div className="case-banner-item" style={{ backgroundImage: `url(${item})` }} />
                          </SwiperSlide>
                        })
                      }
                    </Swiper>
                  </div>
                )
              })
            }
          </div>
        </section>

        <section className="islogan-wrap" style={{ backgroundImage: `url(${IMG_ISLOGAN_BG})` }}>
          <span className="slogan-title">人人可以搭建移动小应用</span>
          <span className="slogan-text">FLOWUP无代码搭建平台，无需一行代码搭建属于你的移动应用。</span>
          <div className="islogan-button">
            {/*<FButton type="ghost" size="medium" style={{ marginRight: 24 }}>立即咨询</FButton>*/}
            {
              userInfo?.recommendCode && <Popconfirm {...{
                title: "邀请链接发给好友，好友通过邀请完成注册并产生订单；在后续完成的订单中，您可以获得每一笔订单金额的10%的收入。",
                okText: '复制链接',
                cancelText: '取消',
                onConfirm: async () => {
                  try {
                    const targetText = `https://info.superfat.cn/login?type=${CONST.ACCOUNT_ACTION_TYPE_REGISTER}&rcode=${userInfo.recommendCode}`
                    copyText(targetText);
                    message.success('已复制');
                  } catch (err) {
                    console.error('Failed to copy: ', err);
                  }
                }
              }}>
                <FButton type="ghost" size="large" style={{ marginRight: 24 }}>我要推荐</FButton>
              </Popconfirm>
            }
            <FButton size="large" onClick={toAppHandle}>极速搭建</FButton>
          </div>
        </section>

        <section className="copyright-wrap">Copyright ©2023 FLY FLOW｜沪ICP备2022007504号-1｜沪公网安备 31011702008566号</section>
      </section>
    </Page>
  );
};

export default PageHomeModel;
