/**
 * Created by jimmy on 2022/6/1.
 */

import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {Tabs, Form, Input, Space, Button, Divider, Modal, message, Popover, Typography} from 'antd';

import * as REQUEST from "../../common/request";
import * as Utils from "../../common/utils";
import * as config from "../../common/config";
import * as CONST from "../../common/const";
import Page from '../../components/page';
import FButton from '../../components/fbutton';

import './index.less';

import IMG_DEFAULT_APP_LOGO from './images/default-app-logo.png';
import moment from "moment";
import {APP_STATUS_EXPIRED, APP_STATUS_INIT} from "../../common/const";
import {DeleteOutlined, LoadingOutlined, WarningOutlined} from "@ant-design/icons";

const PageAppsModel = props => {
  const history = useHistory();
  let initTimer = null;
  const [ list, setList ] = useState([]);
  const getList = async () => {
    clearTimeout(initTimer);
    initTimer = null;

    const result = await REQUEST.get({
      hideLoading: true,
      url: '/app/list'
    });
    if(!result) return;
    setList(result.data.list);

    // loading initial checking
    let initFlag = false;
    result.data.list.map(item => {
      if(item.status == CONST.APP_STATUS_INIT) initFlag = true;
      item.key = item.id;
      return item;
    });
    if(initFlag) {
      initTimer = setTimeout(() => {
        getList();
      }, 555);
    }
  };
  useEffect(() => {
    getList();

    return () => false;
  }, [ 123 ]);

  const [ modalVisible, setModalVisible ] = useState(false);
  const [ appName, setAppName ] = useState('');
  const submitAppHandle = async () => {
    if(!appName) {
      message.error('请输入应用名称');
      return;
    }

    const result = await REQUEST.post({
      url: '/app/add',
      data: {
        name: appName
      }
    });
    if(!result) return;

    message.success('添加成功');
    setModalVisible(false);
    setAppName('');
    getList();
  };

  const deleteHandle = async (id) => {
    const result = await REQUEST.post({
      url: '/app/delete',
      data: {
        id
      }
    });
    if(!result) return;

    message.success('操作成功');
    getList();
  }

  return (
    <Page title="我的应用" mode={CONST.HEADER_MODE_ACCOUNT}>
      <section className="apps-container">
        <div className="apps-banner">人人可以搭建移动应用</div>
        <div className="apps-list-wrap main-container">
          <span>我的应用（{list?.length}）</span>
          <div className="app-list">
            {
              list?.length > 0 && list.map((item, idx) => {
                return (
                  <div className="app-item" key={idx}>
                    <div className="app-content">
                      <div className="app-logo" style={{ backgroundImage: `url(${IMG_DEFAULT_APP_LOGO})` }} />
                      <div className="app-info">
                        <span className="name">{item.name}</span>
                        <span className="time">创建时间：{item.registerTime && moment(item.registerTime).format('YYYY-MM-DD') || '-'}</span>
                      </div>
                      <div className="" onClick={() => {
                        Modal.confirm({
                          title: '应用删除了将无法找回，请慎重操作！',
                          icon: <WarningOutlined style={{ color: '#E45357' }} />,
                          okText: '确认删除',
                          okType: 'danger',
                          cancelText: '取消',
                          onOk: () => {
                            deleteHandle(item.id);
                          },
                          onCancel: () => {
                            console.log('Cancel');
                          },
                        });
                      }}><DeleteOutlined style={{ color: '#c8c8c8' }} /></div>
                    </div>

                    {
                      item.status == CONST.APP_STATUS_INIT && <FButton disabled={item.status == CONST.APP_STATUS_INIT} type="waiting" size="large" style={{
                        height: window.pxToRem(40),
                        fontSize: window.pxToRem(14),
                        marginTop: window.pxToRem(32)
                      }}><LoadingOutlined style={{ marginRight: 8 }} /> 初始化中，预计2分钟</FButton>
                    }
                    {
                      item.status == CONST.APP_STATUS_ONLINE && <FButton type="ghost-revert" size="large" style={{
                        height: window.pxToRem(40),
                        marginTop: window.pxToRem(56),
                        backgroundColor: '#F3F3F3',
                        border: 'none',
                        color: 'rgba(0, 0, 0, 0.6)',
                        fontSize: window.pxToRem(14),
                      }} onClick={() => {
                        window.open(`https://${item.serialNumber}.admin.info.superfat.cn`);
                      }}>进入工作台</FButton>
                    }
                    {
                      item.status == CONST.APP_STATUS_EXPIRED && <FButton disabled={item.status == CONST.APP_STATUS_EXPIRED} type="disabled" size="large" style={{ paddingBottom: window.pxToRem(9), paddingTop: window.pxToRem(9), marginTop: window.pxToRem(32) }}>已过期</FButton>
                    }
                  </div>
                )
              })
            }
            <div className="app-item app-add-item" key="add-item" onClick={() => {
              setModalVisible(true);
            }}>
              <Typography.Link>创建应用</Typography.Link>
            </div>
          </div>
        </div>
        <div className="apps-welcome">欢迎来到 FLOW UP 移动应用无代码搭建平台</div>
      </section>

      <Modal {...{
        open: modalVisible,
        header: null,
        footer: null,
        width: window.pxToRem(400),
        maskClosable: false,
        destroyOnClose: true,
        onCancel: () => {
          setModalVisible(false);
          setAppName('');
        },
      }}>
        <div className="app-form-dialog">
          <span className="title">创建应用</span>
          <span className="desc">无需代码技术，轻松创建移动应用。</span>
          <Form.Item style={{ width: '100%', marginBottom: window.pxToRem(50) }}>
            <Input size="large" placeholder="请输入应用名称" allowClear onChange={e => {
              setAppName(e.currentTarget.value);
            }} />
          </Form.Item>
          <Button size="large" block type="primary" onClick={submitAppHandle}>立即创建</Button>
        </div>
      </Modal>
    </Page>
  );
};

export default PageAppsModel;
