/**
 * Created by jimmy on 2022/6/1.
 */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {Result, Button, Steps, Input, Form, Radio, message, Col, Divider,} from 'antd';

import * as REQUEST from "../../../common/request";
import * as CONST from "../../../common/const";
import * as Utils from "../../../common/utils";

import Page from '../../../components/page';
import '../index.less';
import * as config from "../../../common/config";
import ListFormItem from "../../../components/list/listFormItem";
import {ENTERPRISE_REAL_OPERATOR_LABEL} from "../../../common/const";

const CompCertStep0Model = props => {
  const { data } = props;

  return (
    <div style={props.style}>
      <Divider orientation="left">法人信息</Divider>
      {
        [
          { required: true, label: '最终受益人', name: 'beneficiary', placeholder: '请选择', type: 'radio', options: Utils.selectOptionsFormatter(CONST.BOOL_LABEL), extra: '最终受益人是直接或间接拥有超过25%公司股权或表决权的自然人；通过人事、财务等其他方式对公司进行控制的自然人；公司的高级管理人员。' },
          { required: true, label: '证件类型', name: 'certificationType', placeholder: '请选择', type: 'select', options: Utils.selectOptionsFormatter(CONST.CERTIFICATION_TYPE_LABEL) },
          { required: true, label: '法定代表人姓名', name: 'legalRepresentative', placeholder: '请输入', type: 'input' },
          { required: true, label: '证件号码', name: 'certificationNumber', placeholder: '请输入', type: 'input' },
          { required: true, label: '出生日期', name: 'birthday', placeholder: '请输入', type: 'date' },
          { required: true, label: '证件有效期', name: 'certificationPeriod', placeholder: '请选择', type: 'date-range' },
          { required: true, label: '居住地址', name: 'address', placeholder: '请输入', type: 'textarea' },
          {
            required: true, label: '证件照（正面）', name: 'certificationCover', type: 'upload', extra: '如你的证件类型是身份证，请在此处上传人像面，若为复印件，需加盖公司鲜章。注意：证件盖章不支持电子章。如果是护照，需要提供法人证明函，加盖公司鲜章，与护照照片拼图上传；也可提供护照翻译件，加盖翻译公司鲜章，与护照照片拼图上传。',
            options: {
              pictureLength: 1,
              action: `${config.uploadPrefix}/enterprise/info/upload`,
              defaultFileList:  data?.certificationCover ? [ data.certificationCover ] : [],
              changeHandle: fileList => {
                // props.form.setFieldValue('certificationCover', fileList[0]);
                if(fileList[0] && fileList[0] != data?.certificationCover) {
                  props.form.setFieldValue('certificationCover', fileList[0]);
                  if(typeof props?.updateDataHandle == 'function') {
                    props.updateDataHandle({ certificationCover: fileList[0] } )
                  }
                }
              },
            }
          },
          {
            required: true, label: '证件照（反面）', name: 'certificationCoverReverse', type: 'upload', extra: '如你的证件类型是身份证，请在此处上传国徽面，若为复印件，需加盖公司鲜章。注意：证件盖章不支持电子章。如果是护照，需要提供法人证明函，加盖公司鲜章，与护照照片拼图上传；也可提供护照翻译件，加盖翻译公司鲜章，与护照照片拼图上传。',
            options: {
              pictureLength: 1,
              action: `${config.uploadPrefix}/enterprise/info/upload`,
              defaultFileList:  data?.certificationCoverReverse ? [ data.certificationCoverReverse ] : [],
              changeHandle: fileList => {
                // props.form.setFieldValue('certificationCoverReverse', fileList[0]);
                if(fileList[0] && fileList[0] != data?.certificationCoverReverse) {
                  props.form.setFieldValue('certificationCoverReverse', fileList[0]);
                  if(typeof props?.updateDataHandle == 'function') {
                    props.updateDataHandle({ certificationCoverReverse: fileList[0] } )
                  }
                }
              },
            }
          },
          // { label: '备注信息', name: 'extra', placeholder: '请输入', type: 'textarea' },
        ].map((_item, _idx) => {
          return (
            <ListFormItem item={_item} style={{ padding: 0 }} />
          )
        })
      }
      <Divider orientation="left">实际操作人</Divider>
      {
        [
          { required: true, label: '实际操作人', name: 'realOperator', placeholder: '请选择', type: 'radio', options: Utils.selectOptionsFormatter(CONST.ENTERPRISE_REAL_OPERATOR_LABEL), extra: '当实际操作人不为法人时，即为代办人。代办人需法人授权，提供授权书。' },
          { required: true, label: '证件类型', name: 'agentCertificationType', placeholder: '请选择', type: 'select', options: Utils.selectOptionsFormatter(CONST.CERTIFICATION_TYPE_LABEL) },
          { required: true, label: '代办人姓名', name: 'agentName', placeholder: '请输入', type: 'input' },
          { required: true, label: '证件号码', name: 'agentCertificationNumber', placeholder: '请输入', type: 'input' },
          { required: true, label: '出生日期', name: 'agentBirthday', placeholder: '请输入', type: 'date' },
          { required: true, label: '证件有效期', name: 'agentCertificationPeriod', placeholder: '请选择', type: 'date-range' },
          {
            required: true, label: '代办人证件照（正面）', name: 'agentCertificationCover', type: 'upload', extra: '如你的证件类型是身份证，请在此处上传人像面，若为复印件，需加盖公司鲜章。注意：证件盖章不支持电子章。如果是护照，需要提供法人证明函，加盖公司鲜章，与护照照片拼图上传；也可提供护照翻译件，加盖翻译公司鲜章，与护照照片拼图上传。',
            options: {
              pictureLength: 1,
              action: `${config.uploadPrefix}/enterprise/info/upload`,
              defaultFileList:  data?.agentCertificationCover ? [ data.agentCertificationCover ] : [],
              changeHandle: fileList => {
                // props.form.setFieldValue('agentCertificationCover', fileList[0]);
                if(fileList[0] && fileList[0] != data?.agentCertificationCover) {
                  props.form.setFieldValue('agentCertificationCover', fileList[0]);
                  if(typeof props?.updateDataHandle == 'function') {
                    props.updateDataHandle({ agentCertificationCover: fileList[0] } )
                  }
                }
              },
            }
          },
          {
            required: true, label: '代办人证件照（反面）', name: 'agentCertificationCoverReverse', type: 'upload', extra: '如你的证件类型是身份证，请在此处上传国徽面，若为复印件，需加盖公司鲜章。注意：证件盖章不支持电子章。如果是护照，需要提供法人证明函，加盖公司鲜章，与护照照片拼图上传；也可提供护照翻译件，加盖翻译公司鲜章，与护照照片拼图上传。',
            options: {
              pictureLength: 1,
              action: `${config.uploadPrefix}/enterprise/info/upload`,
              defaultFileList:  data?.agentCertificationCoverReverse ? [ data.agentCertificationCoverReverse ] : [],
              changeHandle: fileList => {
                // props.form.setFieldValue('agentCertificationCoverReverse', fileList[0]);
                if(fileList[0] && fileList[0] != data?.agentCertificationCoverReverse) {
                  props.form.setFieldValue('agentCertificationCoverReverse', fileList[0]);
                  if(typeof props?.updateDataHandle == 'function') {
                    props.updateDataHandle({ agentCertificationCoverReverse: fileList[0] } )
                  }
                }
              },
            }
          },
          {
            required: true, label: '代办授权书照', name: 'agentAuthLetter', type: 'upload', extra: '请先下载模板，填写完成并盖章，拍照或扫描成图片格式上传。',
            options: {
              pictureLength: 1,
              action: `${config.uploadPrefix}/enterprise/info/upload`,
              defaultFileList:  data?.agentAuthLetter ? [ data.agentAuthLetter ] : [],
              changeHandle: fileList => {
                // props.form.setFieldValue('agentAuthLetter', fileList[0]);
                if(fileList[0] && fileList[0] != data?.agentAuthLetter) {
                  props.form.setFieldValue('agentAuthLetter', fileList[0]);
                  if(typeof props?.updateDataHandle == 'function') {
                    props.updateDataHandle({ agentAuthLetter: fileList[0] } )
                  }
                }
              },
            }
          },
          // { label: '备注信息', name: 'agentExtra', placeholder: '请输入', type: 'textarea' },
        ].map((_item, _idx) => {
          return (
            <ListFormItem item={_item} style={{ padding: 0 }} />
          )
        })
      }

      <Form.Item label=" " colon={false}>
        <Button style={{ marginRight: 16 }} onClick={() => {
          if(typeof props?.handleChange == 'function') {
            props.handleChange(-1);
          }
        }}>上一步</Button>
        <Button type="primary" onClick={() => {
          if(typeof props?.handleChange == 'function') {
            props.handleChange(1);
          }
        }}>下一步</Button>
      </Form.Item>
    </div>
  );
};

export default CompCertStep0Model;
