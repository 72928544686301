/**
 * Created by jimmy on 2022/6/1.
 */

import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Result } from 'antd';

import * as REQUEST from "../../common/request";
import * as Utils from "../../common/utils";
import Page from '../../components/page';

import './index.less';
import * as CONST from "../../common/const";

const DETAIL_TARGET = {
  404: {
    title: '404 Not Found',
    subTitle: '抱歉，您访问的页面不存在。',
  },
  500: {
    title: '500',
    subTitle: '抱歉，服务器开小差了。',
  }
}

const PageLoginModel = props => {
  const history = useHistory();
  const { type } = useParams();
  if(!Object.keys(DETAIL_TARGET).includes(type)) {
    window.location.replace('/result/404');
    return;
  }

  const targetOption = DETAIL_TARGET[type];

  return (
    <Page title={targetOption.title} mode={CONST.HEADER_MODE_ACCOUNT}>
      <section className="result-container">
        <Result
          status={type}
          title={targetOption.title}
          subTitle={targetOption.subTitle}
          extra={<Button type="primary" onClick={() => history.push('/')}>返回首页</Button>}
        />
      </section>
    </Page>
  );

  return null;
};

export default PageLoginModel;
