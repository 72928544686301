import * as CONF from './config';
import * as REQUEST from "./request";
import {message} from "antd";

export const setPageTitle = (title = 'FLOW UP') => {
  if(title != 'FLOW UP') {
    title = `FLOW UP | ${title}`
  }
  document.title = title;
}

export const localStorage = {
  get: key => {
    let ls = {};
    try {
      ls = JSON.parse(window.localStorage.getItem(key)) || {};
    } catch (e) {}
    return ls[key];
  },
  set: (key, value = {}) => {
    try {
      window.localStorage.setItem(
        key,
        JSON.stringify(value)
      );
    }catch (e){}
  }
};

// loading
export const loading = {
  show: () => {
    document.getElementById('loading').style.display = 'block';
  },
  hide: () => {
    document.getElementById('loading').style.display = 'none';
  }
};

export const toast = {
  show: (txt) => {
    const $toast = document.getElementById('toast');
    $toast.style.display = 'block';
    $toast.innerText = txt;
  },
  hide: () => {
    const $toast = document.getElementById('toast');
    $toast.style.display = 'none';
    $toast.innerText = '';
  }
};

export const getUA = () => {
  if(!window || !window.navigator || !window.navigator.userAgent){
    return null;
  }

  const ua = window.navigator.userAgent.toLocaleLowerCase();

  return {
    ua,
    isInWechat: ua.indexOf('micromessenger') > -1,
    isInRongMeiApp: ua.indexOf('rongmeiapp/qingpu') > -1 || ua.indexOf('rongmeiapp') > -1,    // mainly for QingPu
    isIOS: ua.indexOf("iphone") > -1 || ua.indexOf("ios") > -1,
    isAndroid: ua.indexOf("android") > -1 || ua.indexOf("linux") > -1
  }
};

export const getTargetByKey = (array = [], key, value) => {
  const [ target = {} ] = array.filter(item => item[key] == value);
  return target;
};

export const getFromMapByKey = (map, key) => {
  let target = null;
  if(map[key]) {
    target = map[key];
  }else{
    for(let i in map) {
      if(map[i] == key) {
        target = i;
      }
    }
  }
  return target;
};

export const transferMapToArray = (map, keyField, valueField) => {
  const arr = [];
  Object.keys(map).map(key => {
    arr.push({
      [keyField]: key,
      [valueField]: map[key]
    });
  });
  return arr;
};

export const getSearchByKey = (key, search = window.location.search) => {
  if(!search) return '';

  const _search = search.slice(1);
  const _searchArr = _search.split('&');
  const _map = {};
  _searchArr.map(item => {
    const _item = item.split('=');
    if(_item[0] && _item[1]) {
      _map[_item[0]] = _item[1];
    }
  });
  return _map[key] || '';
};

/**
 * 有效手机号码验证
 * @param mobile
 * @returns {boolean}
 */
export const mobileVerify = mobile => {
  return /^1[3456789]\d{9}$/.test(mobile);
};

/**
 * 有效账户名验证
 * @param account
 * @returns {boolean}
 */
export const accountVerify = account => {
  return /^[_a-zA-Z0-9]+$/.test(account);
};

/**
 * 倒计时
 * @param second，默认60秒
 * @param callback
 * @returns {{start: start}}
 */
export const countdown = (second = 60, callback) => {
  let timer = null;
  return {
    start: () => {
      if(second < 0) {
        clearTimeout(timer);
        timer = null;
        callback && typeof callback == 'function' && callback();
        return;
      }
      timer = setTimeout(function(){
          callback && typeof callback == 'function' && callback(second);
          second -= 1;

          countdown(second, callback).start();
        }
        ,1000);
    }
  }
};

export const editorUploadHelper = (settings = {}) => {
  const serverURL = CONF.uploadPrefix + settings.url;
  return (param) => {
    const xhr = new XMLHttpRequest;
    const fd = new FormData();
    const successFn = (event) => {
      let { response } = event.target;
      response = JSON.parse(response);
      // 假设服务端直接返回文件上传后的地址
      // 上传成功后调用param.success并传入上传后的文件地址
      param.success({
        url: CONF.imgPrefixMobile + response.data.src,
        meta: {
          id: param.id,
          title: 'xxx',
          alt: 'xxx',
          loop: true, // 指定音视频是否循环播放
          autoPlay: true, // 指定音视频是否自动播放
          controls: true, // 指定音视频是否显示控制栏
          poster: CONF.imgPrefixMobile + response.data.src, // 指定视频播放器的封面
        }
      })
    };
    const progressFn = (event) => {
      // 上传进度发生变化时调用param.progress
      param.progress(event.loaded / event.total * 100)
    };
    const errorFn = (response) => {
      // 上传发生错误时调用param.error
      param.error({
        msg: 'unable to upload.'
      })
    };
    xhr.upload.addEventListener("progress", progressFn, false);
    xhr.addEventListener("load", successFn, false);
    xhr.addEventListener("error", errorFn, false);
    xhr.addEventListener("abort", errorFn, false);

    fd.append('file', param.file);
    xhr.open('POST', serverURL, true);
    xhr.send(fd);
  };
}

export const getImageSize = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = function () {
      const width = this.width;
      const height = this.height;
      resolve({ width, height, radio: width/height });
    }
  })
};

export const imageUploadSizeVerify = (file, showErrTip = true) => {
  const msize = 100;
  const flag = file.size / 1024 / 1024 < msize;
  if (!flag && showErrTip) {
    message.error(`上传图片大小不能超过${msize}M`);
  }
  return flag;
};

export const genRandomString = (len = 6) => {
  let strs = 'PLMOKJNIUHBYGVCFTRDXSEWQAZqazswxcderfvbgtyhnujmkiopl123456987';
  let strsLength = strs.length;
  let str = '';
  for(let i = 0; i < len; i ++){
    str += strs[parseInt(Math.random() * strsLength)];
  }
  return str;
};

export const toLoginPage = () => {
  if(window.location.href.indexOf('login') === -1) {
    window.location.replace('/login');
  }
};

export const selectOptionsFormatter = (sourceMap) => {
  const list = [];
  Object.keys(sourceMap).map(key => {
    list.push({
      value: Number(key),
      label: sourceMap[key]
    });
  });
  return list;
};

export const selectOptionsFormat = (sourceList, valueField, labelField) => {
  const list = [];
  sourceList.map(item => {
    if(valueField && labelField) {
      list.push({
        value: Number(item[valueField]),
        label: item[labelField]
      });
    }
  });
  console.log('list', list);
  return list;
};

export const autoCompleteOptionsFormat = (list = [], field) => {
  return list.map(lst => {
    let value;
    if(typeof lst == 'string') {
      value = lst;
    }else{
      if(typeof field == 'function') {
        value = field(lst);
      }else{
        value = lst[field];
      }
    }
    if(value) {
      return { value, label: value }
    }
  });
};

export const getDownloadLink = (src) => {
  return CONF.imgPrefix + src;
}

export const sleep = (timeout = 10000) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, Number(timeout));
  });
}

export const compareVersion = (newVersion, oldVersion) => {
  console.log('newVersion, oldVersion', newVersion, oldVersion)
  const arr1 = newVersion.split('.')
  const arr2 = oldVersion.split('.')
  if(arr1.length != 3) {
    return -1;
  }
  const length1 = arr1.length
  const length2 = arr2.length
  console.log('length1', length1, 'length2', length2)
  const minlength = Math.min(length1, length2)
  let i = 0
  for (i ; i < minlength; i++) {
    let a = parseInt(arr1[i])
    let b = parseInt(arr2[i])
    if (a > b) {
      return 1
    } else if (a < b) {
      return -1
    }
  }
  if (length1 > length2) {
    for(let j = i; j < length1; j++) {
      if (parseInt(arr1[j]) != 0) {
        return 1
      }
    }
    return 0
  } else if (length1 < length2) {
    for(let j = i; j < length2; j++) {
      if (parseInt(arr2[j]) != 0) {
        return -1
      }
    }
    return 0
  }
  return 0
};
