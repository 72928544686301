/**
 * Created by jimmy on 2022/6/1.
 */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {Result, Button, Steps, Input, Form, Radio, message, Col,} from 'antd';

import * as REQUEST from "../../../common/request";
import * as CONST from "../../../common/const";
import * as Utils from "../../../common/utils";

import Page from '../../../components/page';
import '../index.less';
import * as config from "../../../common/config";
import ListFormItem from "../../../components/list/listFormItem";

const CompCertStep0Model = props => {
  const { data } = props;
  const history = useHistory();

  return (
    <div style={props.style}>
      {
        [
          { required: true, label: '主体信息', name: 'type', placeholder: '请选择', type: 'radio', options: Utils.selectOptionsFormatter(CONST.SUBJECT_TYPE_LABEL) },
          { required: true, label: '企业名称', name: 'name', placeholder: '请输入', type: 'input', extra: '该名称需与在有赞的提现银行卡所对应的对公账户名称一致' },
          { required: true, label: '注册地址', name: 'registerAddress', placeholder: '请输入', type: 'input', extra: '注册地址需与营业执照登记住所一致' },
          { required: true, label: '经营范围', name: 'businessScope', placeholder: '请输入', type: 'textarea', extra: '与企业工商营业执照上一致' },
          { required: true, label: '统一社会信用代码', name: 'unifiedSocialCreditCode', placeholder: '请输入', type: 'input', extra: '与企业工商营业执照上一致' },
          { required: true, label: '营业期限', name: 'operationPeriod', placeholder: '请选择', type: 'date-range' },
          {
            required: true, label: '营业执照', name: 'businessLicenseCover', type: 'upload',
            options: {
              pictureLength: 1,
              action: `${config.uploadPrefix}/enterprise/info/upload`,
              defaultFileList: data?.businessLicenseCover ? [ data.businessLicenseCover ] : [],
              changeHandle: fileList => {
                if(fileList[0] && fileList[0] != data?.businessLicenseCover) {
                  props.form.setFieldValue('businessLicenseCover', fileList[0]);
                  if(typeof props?.updateDataHandle == 'function') {
                    props.updateDataHandle({ businessLicenseCover: fileList[0] } )
                  }
                }
              },
            }
          },
          // { label: '备注信息', name: 'extra', placeholder: '请输入', type: 'textarea' },
        ].map((_item, _idx) => {
          return (
            <ListFormItem item={_item} style={{ padding: 0 }} />
          )
        })
      }

      <Form.Item label=" " colon={false}>
        <Button style={{ marginRight: 16 }} onClick={() => {
          if(typeof props?.handleChange == 'function') {
            props.handleChange(-1);
          }
        }}>上一步</Button>
        <Button type="primary" onClick={() => {
          if(typeof props?.handleChange == 'function') {
            props.handleChange(1);
          }
        }}>下一步</Button>
      </Form.Item>
    </div>
  );
};

export default CompCertStep0Model;
