/**
 * Created by jimmy on 2022/6/1.
 */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {Result, Button, Steps, Input, Form, Radio, message, Col, Divider,} from 'antd';

import * as REQUEST from "../../../common/request";
import * as CONST from "../../../common/const";
import * as Utils from "../../../common/utils";

import Page from '../../../components/page';
import '../index.less';
import * as config from "../../../common/config";
import ListFormItem from "../../../components/list/listFormItem";
import {CERTIFICATION_TYPE_LABEL, ENTERPRISE_REAL_OPERATOR_LEGAL} from "../../../common/const";

const CompCertStep4Model = props => {
  const history = useHistory();

  return (
    <div style={props.style}>
      <Result
        status="info"
        title="审核中……"
        subTitle="资料已提交成功，请等待审核结果；审核结果将在10个工作日内公示。"
      />
    </div>
  );
};

export default CompCertStep4Model;
