/**
 * Created by jimmy on 2022/6/1.
 */

import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import {Popover, message, Breadcrumb, Typography, Badge, ConfigProvider} from 'antd';
import { CarFilled, CarOutlined, BellFilled, QuestionCircleFilled, MessageOutlined, GlobalOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import enUS from 'antd/lib/locale/en_US';
import thTH from 'antd/lib/locale/th_TH';
// import * as LOCALE from '../../common/locale';

import Menu from '../menu';

import FButton from '../../components/fbutton';
import FHeader from '../../components/header';

import * as Utils from '../../common/utils';
import * as CONF from '../../common/config';
import * as REQUEST from '../../common/request';
import * as CONST from '../../common/const';
import './index.less';

import IMG_LOGO_WHITE from '../../common/images/logo-white.png';
import IMG_LOGO_MAIN from '../../common/images/logo-main.png';

const CompPageModel = props => {
  const { title, needLogin = true } = props;

  useEffect(() => {
    Utils.setPageTitle(title);
    return () => false;
  }, [ title ]);

  const [ userInfo, setUserInfo ] = useState(null);
  const getUserInfo = async () => {
    const result = await REQUEST.get({
      url: '/account/get',
      keepStatus: true
    });
    if(!result) return;
    setUserInfo(result.data || {});
  };
  useEffect(() => {
    getUserInfo();

    return () => false;
  }, []);

  return (
    <section className="component-page-container">
      <FHeader {...props} userInfo={userInfo} />
      <section className="component-page-body">{props.children}</section>
    </section>
  );
};

export default CompPageModel;
