import {AppstoreOutlined, GiftOutlined, GlobalOutlined, HomeOutlined, UserOutlined} from "@ant-design/icons";
import React from "react";

/**
 * Created by jimmy on 2019/11/8.
 */

export const host = window.location.origin;
export const env = (host.indexOf('127.0.0.1') > -1 || host.indexOf('local') > -1) ? 'dev' : 'product';

export const apiPrefix = '/api/v1';
export const uploadPrefix = '/api/v1';
export const imgPrefixMobile = env === 'product' ? `https://info-1304488224.cos.ap-shanghai.myqcloud.com/` : 'http://127.0.0.1:5987/';
export const imgPrefix = env === 'product' ? `https://info-1304488224.cos.ap-shanghai.myqcloud.com/` : 'http://127.0.0.1:5987/';
export const videoPrefix = `/`;

export const appName = '资讯管理后台';

export const pageLimit = 20;

// 最大删除数据数量
export const deleteMaxCount = 10;

// 最大导出数据数量
export const exportMaxCount = 30;

export const regexpPattern = {
  mobile: /^1[3456789]\d{9}$/,
};

export const eventKeys = {
  listDrawerToggle: 'list-drawer-toggle',
  listDrawerType: 'list-drawer-type',
  leftMenuToggle: 'left-menu-toggle',
};

export const statisticsInterval = [ 1, 7, 30 ];

export const lbskey = 'QKNBZ-GK4ED-77H4V-PTGAQ-AIQKQ-UQFRT';  // https://lbs.qq.com/

export const iconList = [
  { label: '分类', icon: <AppstoreOutlined />, id: 'category' },
  { label: '我的', icon: <UserOutlined />, id: 'user' },
  { label: '首页', icon: <HomeOutlined />, id: 'home' },
  { label: '发现', icon: <GlobalOutlined />, id: 'found' },
  { label: '活动', icon: <GiftOutlined />, id: 'gift' },
];
