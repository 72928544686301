/**
 * Created by jimmy on 2022/6/1.
 */

import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {Menu, Breadcrumb, Input, Result} from 'antd';
import Page from '../../components/page';
import './index.less';
import './doc.less';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as CONST from '../../common/const';

const PageDocDetailModel = props => {
  const location = useLocation();

  const [ data, setData ] = useState(null);
  const [ init, setInit ] = useState(false);
  const getData = async () => {
    const sn = Utils.getSearchByKey('sn');
    const result = await Request.get({
      url: '/doc/get',
      data: {
        sn
      }
    });
    if(!result) return;

    setData(result.data);
    setInit(true);
  }
  useEffect(() => {
    getData();
  }, [ location ])

  return (
    <Page {...{
      ...props,
      rawHeader: true,
      needLogin: false,
      title: data?.question
    }}>
      <section className="doc1-detail-container main-container">
        {
          init && <>
            {
              data && <>
                <div className="title">{data?.question}</div>
                { data?.category && CONST.DOCUMENT_QUESTION_CATEGORY_LABEL[data?.category] && <div className="tag"># {CONST.DOCUMENT_QUESTION_CATEGORY_LABEL[data?.category]}</div> || null }
                <div className="content" dangerouslySetInnerHTML={{ __html: data?.content }} />
              </> || <Result status="404" extra="暂无数据" />
            }
          </>
        }
      </section>
    </Page>
  );
};

export default PageDocDetailModel;
