/**
 * Created by jimmy on 2022/6/1.
 */

import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Menu, Breadcrumb } from 'antd';
import Page from '../../components/page';
import './index.less';
import './doc.less';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';

const PageDocModel = props => {
  const { type } = useParams();

  const [ content, setContent ] = useState(null);
  const getContent = async () => {
    if(!type) return;
    const result = await Request.get({
      url: '/protocol/get',
      data: {
        type
      }
    });
    if(!result) return;
    setContent(result.data);
  };
  useEffect(() => {
    getContent();
  }, []);

  return (
    <Page {...{
      ...props,
      rawHeader: true,
      needLogin: false,
      title: Utils.getSearchByKey('title') ? decodeURIComponent(Utils.getSearchByKey('title')) : 'FLOW UP'
    }}>
      <section className="protocol-container" style={{ minHeight: window.innerHeight }}>
        <div dangerouslySetInnerHTML={{ __html: content?.body_html }} />
      </section>
    </Page>
  );
};

export default PageDocModel;
