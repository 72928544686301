/**
 * Created by jimmy on 2022/6/1.
 */

import React, { useState, useEffect } from 'react';

import './index.less';

const CompButtonModel = props => {
  const { type = 'default', size = 'small', style = {}, text = '' } = props;

  const btnCls = [ 'component-button-container' ];
  if(type) btnCls.push(`fbutton-${type}`);
  if(size) btnCls.push(`fbutton-${size}`);

  return (
    <section className={btnCls.join(' ')} style={style} onClick={props.onClick}>{props.children || text}</section>
  );
};

export default CompButtonModel;
