/**
 * Created by jimmy on 2022/6/1.
 */

import React, { useState, useEffect } from 'react';
import {
  DatePicker,
  Cascader,
  Typography,
  Form,
  Input,
  Select,
  Checkbox,
  InputNumber,
  Radio,
  Switch,
  AutoComplete,
  TreeSelect, Rate
} from 'antd';
// import * as CITY from "../../common/city";

import './index.less';
import moment from "moment";
import UploadPictureList from "../../components/uploadPictureList";

const CompListFormItem = props => {
  const { item: oriItem, style } = props;
  const item = {...oriItem};

  item.label = item.label || item.title;
  item.name = item.name || item.dataIndex;

  if(item.type === 'text') {
    item.label = <Typography.Text style={{ color: '#969799' }}>{item.label}：</Typography.Text>
  }else{
    item.label = <Typography.Text type="secondary">{item.label}</Typography.Text>
  }
  // item.style = {
  //   ...item.style,
  //   overflow: 'hidden'
  // }

  const formItemSize = 'middle';
  const formItemStyle = { width: '100%' };

  let dom = null;
  if(item.type === 'input') {
    const placeholder = item.placeholder;
    const _item = {...item};
    delete _item.addonBefore;
    dom = (
      <Form.Item {..._item}>
        <Input {...{
          ...item,
          placeholder,
          allowClear: true,
          size: formItemSize,
          style: formItemStyle
        }} />
      </Form.Item>
    )
  }else if(item.type === 'select') {
    const placeholder = item.placeholder;
    const options = item.options || [];
    delete item.options;
    options.map(o => {
      o.value = Number(o.value);
      return o;
    });
    dom = (
      <Form.Item {...item}>
        <Select {...{
          showSearch: true,
          optionFilterProp: 'children',
          filterOption: (input, option) => (option?.label ?? '').includes(input),
          ...item,
          placeholder,
          size: formItemSize,
          style: formItemStyle,
          allowClear: true,
          options
        }} />
      </Form.Item>
    )
  }else if(item.type == 'treeSelect'){
    const placeholder = item.placeholder;
    const options = item.options || [];
    delete item.options;
    dom = (
      <Form.Item {...item}>
        <TreeSelect {...{
          treeDataSimpleMode: true,
          multiple: true,
          treeData: options,
          placeholder,
          // loadData: async ({ id }) => {
          //   await getCategoryList(id);
          // },
          ...item,
        }} />
      </Form.Item>
    )
  }else if(item.type === 'date') {
    const placeholder = item.placeholder;
    dom = (
      <Form.Item {...item}>
        <DatePicker {...{
          ...item,
          format: 'YYYY-MM-DD',
          placeholder,
          size: formItemSize,
          style: formItemStyle,
          allowClear: true
        }} />
      </Form.Item>
    )
  }else if(item.type === 'date-range') {
    const placeholder = typeof item.placeholder == 'string' ? [ item.placeholder, item.placeholder ] : item.placeholder;
    dom = (
      <Form.Item {...item}>
        <DatePicker.RangePicker {...{
          ...item,
          placeholder,
          size: formItemSize,
          format: 'YYYY-MM-DD',
          // format: ['YYYY-MM-DD', 'YYYY-MM-DD'],
          style: formItemStyle,
          allowClear: true
        }} />
      </Form.Item>
    )
  }else if(item.type === 'inputNumber') {
    const placeholder = item.placeholder;
    const _item = {...item};
    delete _item.addonAfter;
    dom = (
      <Form.Item {..._item}>
        <InputNumber {...{
          ...item,
          placeholder,
          size: formItemSize,
          style: formItemStyle
        }} />
      </Form.Item>
    )
  }else if(item.type === 'textarea') {
    const placeholder = item.placeholder;
    dom = (
      <Form.Item {...item}>
        <Input.TextArea {...{
          rows: 4,
          ...item,
          placeholder,
          allowClear: true,
          size: formItemSize,
          style: formItemStyle
        }} />
      </Form.Item>
    )
  }else if(item.type === 'radio') {
    const placeholder = item.placeholder;
    const options = item.options;
    dom = (
      <Form.Item {...item}>
        <Radio.Group {...{
          options,
          ...item,
          placeholder,
          size: formItemSize,
          style: formItemStyle
        }} />
      </Form.Item>
    )
  }else if(item.type === 'checkbox') {
    const placeholder = item.placeholder;
    const options = item.options;
    dom = (
      <Form.Item {...item}>
        <Checkbox.Group {...{
          options,
          ...item,
          placeholder,
          size: formItemSize,
          style: formItemStyle
        }} />
      </Form.Item>
    )
  }else if(item.type === 'switch') {
    dom = (
      <Form.Item {...{
        valuePropName: 'checked',
        ...item
      }}>
        <Switch disabled={item.disabled} />
      </Form.Item>
    )
  }else if(item.type === 'autoComplete') {
    const placeholder = item.placeholder;
    const options = item.options;
    dom = (
      <Form.Item {...item}>
        <AutoComplete {...{
          options,
          placeholder,
          filterOption: true,
          allowClear: true,
        }} />
      </Form.Item>
    )
  }else if(item.type === 'city') {
    // const placeholder = item.placeholder;
    // // const options = item.options;
    // dom = (
    //   <Form.Item {...item}>
    //     <Cascader {...{
    //       options: CITY.getList(),
    //       placeholder,
    //       allowClear: true,
    //     }} />
    //   </Form.Item>
    // )
  }else if(item.type === 'text') {
    let str = '--';
    if(item?.data) {
      if(typeof item?.render == 'function') {
        str = item.render(item?.data);
      }else if(item?.name && item?.data[item?.name]) {
        str = item?.data[item?.name];
      }
    }
    const _item = {...item};
    delete _item.render;
    dom = (
      <div style={{ display: 'flex', fontSize: 14, alignItems: 'center', height: 40 }}>
        <Typography.Text style={{ width: 86, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'right' }}>{_item.label}</Typography.Text>
        <Typography.Text style={{ flex: 1 }}>{str}</Typography.Text>
      </div>
    )
  }else if(item.type == 'upload'){
    const options = item.options;
    dom = (
      <Form.Item {...item}>
        <UploadPictureList {...options} />
      </Form.Item>
    )
  }else if(item.type == 'rate'){
    const options = item.options;
    dom = (
      <Form.Item {...item}>
        <Rate />
      </Form.Item>
    )
  }

  return (
    <section className="component-list-container" style={style}>{dom}</section>
  );
};

export default CompListFormItem;
