/**
 * Created by jimmy on 2022/6/1.
 */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {Result, Button, Steps, Input, Form, Radio, message,} from 'antd';

import * as REQUEST from "../../common/request";
import * as CONST from "../../common/const";

import Page from '../../components/page';
import './index.less';

const PageResetPasswordModel = props => {
  const history = useHistory();
  const [ step, setStep ] = useState(0);

  const [ account, setAccount ] = useState('');
  const [ method, setMethod ] = useState(null);
  const [ code, setCode ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ rePassword, setRePassword ] = useState('');
  const accountVerifyHandle = async () => {
    if(!account) {
      message.error('账户不能为空');
      return;
    }

    const result = await REQUEST.post({
      url: '/account/password/reset/verify',
      data: {
        account
      }
    });
    if(!result) return;

    setStep(step + 1);
  };
  const codeSendHandle = async () => {
    if(!account) {
      message.error('账户不能为空');
      return;
    }
    if(!method) {
      message.error('需要选择重置方式');
      return;
    }

    const result = await REQUEST.post({
      url: '/account/password/reset/code/send',
      data: {
        account,
        method
      }
    });
    if(!result) return;

    message.success('验证码发送成功');

    setStep(step + 1);
  }
  const resetHandle = async () => {
    if(!account) {
      message.error('账户不能为空');
      return;
    }
    if(!method) {
      message.error('需要选择重置方式');
      return;
    }
    if(rePassword != password) {
      message.error('两次密码不一致');
      return;
    }

    const result = await REQUEST.post({
      url: '/account/password/reset',
      data: {
        account,
        code,
        password,
        method
      }
    });
    if(!result) return;

    setStep(step + 1);
  }

  return (
    <Page {...{
      rawHeader: true,
      needLogin: false,
      title: '企业认证',
    }}>
    {/*<Page title="重置密码" mode={CONST.HEADER_MODE_RAW}>*/}
      <section className="reset-password-container main-container">
        <Steps current={step} onChange={setStep} type="navigation" size="small">
          <Steps.Step title="输入账户名称" disabled />
          <Steps.Step title="选择重置方式" disabled />
          <Steps.Step title="重置密码" disabled />
          <Steps.Step title="完成" disabled />
        </Steps>

        <div className="reset-password-step-content">
          <Form {...{
            layout: 'vertical'
          }}>
            {
              step == 0 && <Form.Item label="请输入账户名称" required>
                <Input value={account} placeholder="请输入" onChange={e => setAccount(e.currentTarget.value)} />
              </Form.Item>
            }
            {
              step == 1 && <Form.Item label="请选择重置密码方式" required>
                <Radio.Group value={method} onChange={e => {
                  setMethod(e.target.value);
                }}>
                  <Radio value={1} style={{ marginBottom: 10, marginTop: 20 }}>使用手机号验证重置</Radio>
                  <Radio value={2}>使用邮箱验证重置</Radio>
                </Radio.Group>
              </Form.Item>
            }
            {
              step == 2 && <>
                <Form.Item label="请输入收到的验证码" required>
                  <Input value={code} placeholder="请输入" onChange={e => setCode(e.currentTarget.value)} />
                </Form.Item>
                <Form.Item label="请输入新的密码" required>
                  <Input.Password value={password} placeholder="请输入" onChange={e => setPassword(e.currentTarget.value)} />
                </Form.Item>
                <Form.Item label="请再次输入新的密码" required>
                  <Input.Password value={rePassword} placeholder="请输入" onChange={e => setRePassword(e.currentTarget.value)} />
                </Form.Item>
              </>
            }
            {
              step == 3 && <>
                <Result
                  status="success"
                  title="操作成功!"
                  subTitle="恭喜重置密码成功！请重新登录系统继续使用～"
                  extra={[
                    <Button type="primary" onClick={() => {
                      history.push('/login');
                    }}>重新登录</Button>
                  ]}
                />
              </>
            }

            {
              step < 3 && <div style={{ display: 'flex', marginTop: 40 }}>
                {
                  step > 0 && <Form.Item label="" colon={false} style={{ marginRight: 20 }}>
                    <Button onClick={() => {
                      if(step == 0) return;
                      setStep(step - 1);
                    }}>上一步</Button>
                  </Form.Item>
                }
                <Form.Item label="" colon={false}>
                  <Button type="primary" onClick={() => {
                    if(step == 0) {
                      accountVerifyHandle();
                    }
                    if(step == 1) {
                      codeSendHandle();
                    }
                    if(step == 2) {
                      resetHandle();
                    }
                    // setStep(step + 1);
                  }}>{ step < 2 ? '下一步' : '提交' }</Button>
                </Form.Item>
              </div>
            }
          </Form>
        </div>
      </section>
    </Page>
  );
};

export default PageResetPasswordModel;
