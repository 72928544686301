/**
 * Created by jimmy on 2022/6/1.
 */

import React, {useState, useEffect, useRef} from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import {Divider, Menu} from 'antd';
import {
  SettingOutlined,
  ContactsOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PicLeftOutlined,
  ClusterOutlined,
  SendOutlined, DashboardOutlined
} from '@ant-design/icons';

import './index.less';
import * as REQUEST from "../../common/request";
// import * as LOCALE from '../../common/locale';
import EventBus from "../../common/eventBus";
import * as config from "../../common/config";

const MENU = [
  {
    label: '概况', key: '/dashboard', icon: <DashboardOutlined />,
  },
  {
    label: '内容管理', key: 'information', icon: <PicLeftOutlined />,
    children: [
      { label: '分类列表', key: '/category/list' },
      { label: '文章列表', key: '/article/list' },
      { label: '视频列表', key: '/video/list' },
      { label: '评论列表', key: '/comment/list' },
    ]
  },
  {
    label: '搭建装修', key: 'build', icon: <ClusterOutlined />,
    children: [
      { label: '页面列表', key: '/page/list' },
      { label: '全局设置', key: '/build/setting' },   // 皮肤 / 底部导航 / 个人中心等等
    ]
  },
  {
    label: '小程序设置', key: '/wxmini/setting', icon: <SendOutlined />,   // appid / 发布等
  },
  {
    label: '用户中心', key: 'user', icon: <ContactsOutlined />,
    children: [
      { label: '用户列表', key: '/user/list' },
    ]
  },
  {
    label: '系统管理', key: 'system', icon: <SettingOutlined />,
    children: [
      { label: '管理员列表', key: '/admin/list' },
      { label: '角色设置', key: '/role/list' },
      { label: '权限设置', key: '/permission/list' },
      { label: '日志列表', key: '/logs/list' },
    ]
  }
]

const CompMenuModel = props => {
  const location = useLocation();
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);

  const [ menus, setMenus ] = useState([]);
  const initialMenus = async () => {
    const result = await REQUEST.get({
      url: '/admin/get'
    });
    if(!result) return;
    const userInfo = result.data;
    const menus = [];
    if(userInfo?.targetPage?.length > 0) {
      MENU.map(menuItem => {
        if(menuItem?.children?.length > 0) {
          const _children = [];
          menuItem.children.map(citem => {
            if(userInfo.targetPage.includes(citem.key)) {
              _children.push(citem);
            }
          });
          if(_children?.length > 0) {
            menuItem.children = _children;
            menus.push(menuItem);
          }
        }else{
          if(userInfo.targetPage.includes(menuItem.key)) {
            menus.push(menuItem);
          }
        }
      });
    }

    setMenus(menus);
  };
  useEffect(() => {
    initialMenus();
  }, [123]);

  const [ selectedKeys, setSelectedKeys ] = useState([]);
  const [ openKeys, setOpenKeys ] = useState([]);
  useEffect(() => {
    if(menus?.length <= 0) return;

    let selectedKey = location.pathname;
    const _selectedKey = selectedKey.replace(/\/[0-9]+/g,"");
    let openKey = menus[0].key;
    if(selectedKey == '/' || selectedKey == '') {
      selectedKey = menus[0]?.children && menus[0]?.children[0]?.key || menus[0]?.key || null;
    }else{
      selectedKey = selectedKey || menus[0].children[0].key || menus[0].key;
      openKey = (() => {
        let target = menus[0];
        for(let i = 0, len = menus.length; i < len; i++) {
          const menuItem = menus[i];
          if(menuItem?.children?.length > 0) {
            for(let ci = 0, clen = menuItem.children?.length; ci < clen; ci++) {
              if(menuItem.children[ci].key == selectedKey || menuItem.children[ci].key.indexOf(_selectedKey) > -1) {
                target = menuItem;
                selectedKey = menuItem.children[ci].key;
              }
            }
          }else if(menuItem?.key == selectedKey || menuItem?.key.indexOf(_selectedKey) > -1) {
            target = menuItem;
            selectedKey = menuItem.key;
          }
        }

        return target?.key || null;
      })();
    }
    setSelectedKeys([ openKey, selectedKey ]);
    setOpenKeys([openKey]);
  }, [ menus ]);

  useEffect(() => {
    props.collapsedHandle(collapsed);
  }, [ collapsed ]);

  return (
    <aside className="component-nav-container" style={{ width: collapsed ? 72 : 232 }}>
      <section className="main">
        <Menu {...{
          style: { overflow: 'hidden', border: 0, width: '100%' },
          mode: 'inline',
          theme: 'light',
          size: 'small',
          openKeys,
          selectedKeys,
          inlineCollapsed: collapsed,
          items: menus,
          onClick: data => {
            setSelectedKeys([data.key]);
            history.push(`${data.key}`);
          },
          onOpenChange: openKeys => {
            setOpenKeys(openKeys);
          }
        }} />
      </section>
      <div className="collapsed" onClick={() => {
        setCollapsed(!collapsed);
        EventBus.emit(config.eventKeys.leftMenuToggle, { width: collapsed ?  232 : 72 });
      }}>
        {collapsed ? <MenuUnfoldOutlined style={{ fontSize: 16 }} /> : <MenuFoldOutlined style={{ fontSize: 16 }} />}
      </div>
    </aside>
  );
};

export default CompMenuModel;
