/**
 * Created by jimmy on 2022/6/5.
 */
import 'whatwg-fetch';
import { message } from "antd";
import * as Utils from './utils';
import * as CONF from './config';

export const getHeaders = (conf = {}) => {
	const _header = {
		"Content-Type":"application/json; charset=utf-8",
		"Auth-Token": '',
		...conf
	};
	return _header;
};

export const post = async (settings) => {
	let url = CONF.apiPrefix + settings.url;
	if(!settings.hideLoading) Utils.loading.show();
	let response = await window.fetch(url, {
		method: 'POST',
		headers: getHeaders(settings.headers),
		body: JSON.stringify(settings.data || {})
	});
	Utils.loading.hide();
	if(response.status && response.status === 401) {
		message.error('登录信息过期，请重新登录');
		setTimeout(() => {
			Utils.toLoginPage();
		}, 500);
		return;
	}

	response = await response.json();
	if(!settings.hideError && response.ok == -1) {
		message.error(response.msg);
		return false;
	}
	return response;
};

export const get = async (settings = {}) => {
	let url = settings.url.startsWith('http') ? settings.url : CONF.apiPrefix + settings.url;

	if(settings.data) {
		let queryArr = [];
		for(let i in settings.data) {
			if(i && (!!settings.data[i] || settings.data[i] == 0)){
				queryArr.push(`${i}=${settings.data[i]}`);
			}
		}
		url += '?' + queryArr.join('&');
	}

	if(!settings.hideLoading) Utils.loading.show();
	let response = await window.fetch(url, {
		method: 'GET',
		headers: getHeaders(settings.headers)
	});
	Utils.loading.hide();
	if(!settings.keepStatus && response.status && response.status === 401) {
		message.error('登录信息过期，请重新登录');
		setTimeout(() => {
			Utils.toLoginPage();
		}, 500);
		return;
	}

	response = await response.json();
	if(!settings.hideError && response.ok == -1) {
		message.error(response.msg);
		return false;
	}
	return response;
};
