/**
 * Created by jimmy on 2022/6/1.
 */

import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {Tabs, Form, Input, Space, Button, Divider, message, Popover, Typography} from 'antd';

import * as REQUEST from "../../common/request";
import * as Utils from "../../common/utils";
import * as config from "../../common/config";
import * as CONST from "../../common/const";
import Page from '../../components/page';

import './index.less';

import IMG_LOGO_MAIN from './images/logo-main.png';
// import * as LOCALE from "../../common/locale";

const PageLoginModel = props => {
  const history = useHistory();
  const type = Utils.getSearchByKey('type') || CONST.ACCOUNT_ACTION_TYPE_LOGIN;
  const [ actionType, setActionType ] = useState(Number(type));

  const [ account, setAccount ] = useState('');
  const [ code, setCode ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ second, setSecond ] = useState();
  const getCode = async () => {
    if(!Utils.mobileVerify(account)) {
      message.error('请输入有效手机号码');
      return;
    }
    if(!imgCode) {
      message.error('请输入图片验证码');
      return;
    }

    const result = await REQUEST.get({
      url: '/account/code/get',
      data: {
        mobile: account,
        imgCode
      }
    });
    if(!result) return;

    message.success('验证码发送成功');
    Utils.countdown(60, second => {
      setSecond(second);
    }).start();
  };
  const loginHandle = async () => {
    const result = await REQUEST.post({
      url: '/account/login',
      data: {
        account,
        password,
        imgCode
      }
    });
    if(!result) return;

    message.success('登录成功');
    history.push('/apps');
  };

  const [ recommendCode, setRecommendCode ] = useState(Utils.getSearchByKey('rcode'));
  const registerHandle = async () => {
    const result = await REQUEST.post({
      url: '/account/register',
      data: {
        mobile: account,
        password,
        imgCode,
        code,
        recommendCode
      }
    });
    if(!result) return;

    message.success('注册成功，请再次登录');
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const [ imgCode, setImgCode ] = useState('');
  const [ codeImg, setCodeImg ] = useState(null);
  const getCodeImg = async () => {
    const result = await REQUEST.get({
      url: '/account/imgcode/get'
    });
    if(!result) return;

    const codeImg = `data:image/svg+xml;base64,${window.btoa(result.data.img)}`;
    setCodeImg(codeImg);
  };
  useEffect(() => {
    getCodeImg();
  }, [123]);

  const toggleActionType = ($actionType) => {
    setAccount('');
    setCode('');
    setPassword('');
    setImgCode('');
    setActionType($actionType);
  }

  return (
    <section className="login-container">
      <section className="login-wrapper">
        {
          actionType == CONST.ACCOUNT_ACTION_TYPE_LOGIN && <div>
            <div className="login-welcome">
              <img src={IMG_LOGO_MAIN} />
              <span className="title">登录</span>
              <span className="tip">没有账户？<Typography.Link onClick={() => toggleActionType(CONST.ACCOUNT_ACTION_TYPE_REGISTER)}>免费注册</Typography.Link></span>
            </div>

            <Form.Item label="" colon={false}>
              <Input placeholder="请输入手机号" maxLength={11} allowClear size="large" onChange={e => setAccount(e.currentTarget.value)} />
            </Form.Item>
            <Form.Item label="" colon={false}>
              <Input.Password placeholder="请输入密码" allowClear size="large" onChange={e => setPassword(e.currentTarget.value)} />
            </Form.Item>
            <Form.Item label="" colon={false}>
              <Input placeholder="请输入验证码" allowClear size="large" maxLength={6} suffix={
                <img src={codeImg} style={{ height: 25 }} onClick={getCodeImg} />
              } onChange={e => setImgCode(e.currentTarget.value)} />
            </Form.Item>
            <Space style={{ display: 'flex', justifyContent: 'space-between', marginTop: -20 }}>
              <span />
              <Typography.Link href="/password/reset" target="_blank" style={{ fontSize: window.pxToRem(16) }}>忘记密码？</Typography.Link>
            </Space>

            <Form.Item label="" colon={false} style={{ marginBottom: 16 }}>
              <Button type="primary" size="large" style={{ marginTop: 40 }} block onClick={loginHandle}>登录</Button>
            </Form.Item>
          </div>
        }
        {
          actionType == CONST.ACCOUNT_ACTION_TYPE_REGISTER && <div>
            <div className="login-welcome">
              <img src={IMG_LOGO_MAIN} />
              <span className="title">注册</span>
              <span className="tip">已有账户？<Typography.Link onClick={() => toggleActionType(CONST.ACCOUNT_ACTION_TYPE_LOGIN)}>立即登录</Typography.Link></span>
            </div>

            <Form.Item label="" colon={false}>
              <Input placeholder="请输入手机号" maxLength={11} allowClear size="large" onChange={e => setAccount(e.currentTarget.value)} />
            </Form.Item>
            <Form.Item label="" colon={false}>
              <Input placeholder="请输入验证码" allowClear size="large" maxLength={6} suffix={
                <img src={codeImg} style={{ height: 20 }} onClick={getCodeImg} />
              } onChange={e => setImgCode(e.currentTarget.value)} />
            </Form.Item>
            <Form.Item label="" colon={false}>
              <Input placeholder="请输入短信验证码" maxLength={6} allowClear size="large" suffix={
                <Typography.Link onClick={getCode} style={{ fontSize: window.pxToRem(16) }}>{ second > 0 && `${second}s重新获取` || '发送验证码'}</Typography.Link>
              } onChange={e => setCode(e.currentTarget.value)} />
            </Form.Item>
            <Form.Item label="" colon={false}>
              <Input.Password placeholder="请输入密码" allowClear size="large" onChange={e => setPassword(e.currentTarget.value)} />
            </Form.Item>
            <Form.Item label="" colon={false}>
              <Input defaultValue={recommendCode} placeholder="推荐码" allowClear size="large" onChange={e => setRecommendCode(e.currentTarget.value)} />
            </Form.Item>

            <Form.Item label="" colon={false} style={{ marginBottom: 16 }}>
              <Button type="primary" size="large" style={{ marginTop: 40 }} block onClick={registerHandle}>立即注册</Button>
            </Form.Item>
          </div>
        }
        <Typography.Link underline={true} style={{ textAlign: 'center', fontSize: 13 }} onClick={() => window.open(`https://info.superfat.cn/protocol/private?title=${encodeURIComponent('用户协议、隐私声明')}`)}>用户协议、隐私声明</Typography.Link>

        <div className="copyright-announce">欢迎来到 FLOW UP 无代码搭建小程序平台</div>
      </section>
    </section>
  );
};

export default PageLoginModel;
