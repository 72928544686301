/**
 * Created by jimmy on 2022/6/1.
 */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {Popover, message, Breadcrumb, Typography, Badge, ConfigProvider} from 'antd';
import { CarFilled, CarOutlined, BellFilled, QuestionCircleFilled, MessageOutlined, GlobalOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import enUS from 'antd/lib/locale/en_US';
import thTH from 'antd/lib/locale/th_TH';
// import * as LOCALE from '../../common/locale';

import Menu from '../menu';

import FButton from '../../components/fbutton';

import * as Utils from '../../common/utils';
import * as CONF from '../../common/config';
import * as REQUEST from '../../common/request';
import * as CONST from '../../common/const';
import './index.less';

import IMG_LOGO_WHITE from '../../common/images/logo-white.png';
import IMG_LOGO_MAIN from '../../common/images/logo-main.png';
import IMG_AVATAR_DEFAULT from '../../common/images/avatar-default.png';

const CompPageModel = props => {
  const history = useHistory();
  const { userInfo, mode = CONST.HEADER_MODE_DEFAULT } = props;

  const [popoverFlag, setPopoverFlag] = useState(false);

  const logout = async () => {
    const result = await REQUEST.post({
      url: '/account/logout'
    });
    if(!result) return;

    message.success('退出登录成功');

    let timer = setTimeout(() => {
      clearTimeout(timer);
      timer = null;

      history.push('/login');
    }, 888);
  };

  if(mode == CONST.HEADER_MODE_DEFAULT) {
    return (
      <header className="component-page-header">
        <div className="main-container iheader-wrap">
          <div className="ilogo" onClick={() => history.push('/')}><img src={IMG_LOGO_WHITE} /></div>
          <div className="imessage">
            {/*<FButton type="text" style={{ marginRight: 40 }} onClick={() => history.push('/price')}>产品定价</FButton>*/}
            {
              userInfo?.id && <>
                <Popover
                  content={<div style={{ display: 'flex', flexDirection: 'column' }}>
                    {
                      [
                        // { label: '账户管理', action: () => {} },
                        { label: '退出登录', action: () => logout() },
                      ].map((item, idx) => {
                        return <span style={{ textDecoration: 'underline', cursor: 'pointer', height: 32, display: 'flex', alignItems: 'center' }} key={idx} onClick={item.action}>{item.label}</span>
                      })
                    }
                  </div>}
                  trigger="hover"
                  style={{ cursor: 'pointer' }}
                  visible={popoverFlag}
                  onVisibleChange={() => { setPopoverFlag(!popoverFlag) }}
                >
                  <div className="avatar-wrap">
                    <div className="avatar" style={{ backgroundImage: `url(${IMG_AVATAR_DEFAULT})` }}></div>
                    <span style={{ paddingLeft: 4 }}>{userInfo?.name}</span>
                  </div>
                </Popover>
                <FButton size="small" style={{ marginLeft: 16 }} onClick={() => history.push(`/apps`)}>进入工作台</FButton>
              </>
              || <>
                {/*<FButton type="text" style={{ marginRight: 8 }} onClick={() => history.push('/price')}>产品介绍</FButton>*/}
                <FButton type="main" style={{ marginRight: 15 }} onClick={() => history.push(`/login`)}>免费注册</FButton>
                <FButton onClick={() => history.push(`/login`)}>登录工作台</FButton>
              </>
            }
          </div>
        </div>
      </header>
    )
  }
  if(mode == CONST.HEADER_MODE_ACCOUNT) {
    return (
      <header className="component-page-header default">
        <div className="main-container iheader-wrap">
          <div className="ilogo" onClick={() => history.push('/')}><img src={IMG_LOGO_MAIN} /></div>
          <div className="imessage">
            {/*<FButton type="text-revert" onClick={() => history.push('/price')}>产品定价</FButton>*/}
            <FButton type="text-revert" style={{ marginLeft: 12 }} onClick={() => history.push('/doc')}>帮助文档</FButton>
            {
              userInfo?.id && <>
                <Popover
                  content={<div style={{ display: 'flex', flexDirection: 'column' }}>
                    {
                      [
                        // { label: '账户管理', action: () => {} },
                        { label: '退出登录', action: () => logout() },
                      ].map((item, idx) => {
                        return <span style={{ textDecoration: 'underline', cursor: 'pointer', height: 32, display: 'flex', alignItems: 'center' }} key={idx} onClick={item.action}>{item.label}</span>
                      })
                    }
                  </div>}
                  trigger="hover"
                  style={{ cursor: 'pointer' }}
                  visible={popoverFlag}
                  onVisibleChange={() => { setPopoverFlag(!popoverFlag) }}
                >
                  <div className="avatar-wrap">
                    <div className="avatar" style={{ backgroundImage: `url(${IMG_AVATAR_DEFAULT})` }}></div>
                    <span style={{ paddingLeft: 4 }}>{userInfo?.name}</span>
                  </div>
                </Popover>
                {/*<FButton size="small" style={{ marginLeft: 16 }} onClick={() => history.push(`/apps`)}>进入工作台</FButton>*/}
              </>
            }
          </div>
        </div>
      </header>
    )
  }
  if(mode == CONST.HEADER_MODE_RAW) {
    return (
      <header className="component-page-header default">
        <div className="main-container iheader-wrap">
          <div className="ilogo" onClick={() => history.push('/')}><img src={IMG_LOGO_MAIN} /></div>
          <div className="imessage">
            <FButton type="text-revert" onClick={() => history.push('/doc')}>帮助文档</FButton>
          </div>
        </div>
      </header>
    )
  }
  return null;
};

export default CompPageModel;
