/**
 * Created by jimmy on 2022/6/1.
 */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {Menu, Breadcrumb, Input, Result} from 'antd';
import Page from '../../components/page';
import './index.less';
import './doc.less';

import * as Request from '../../common/request';
import {DOCUMENT_QUESTION_CATEGORY_LABEL} from "../../common/const";

const PageDocModel = props => {
  const history = useHistory();

  const [ searched, setSearched ] = useState(false);
  const [ results, setResults ] = useState([]);
  const [ searchKey, setSearchKey ] = useState('');
  const getList = async (data) => {
    if(!data?.key) return;

    const result = await Request.get({
      url: '/doc/list',
      data
    });
    if(!result) return;

    setResults(result.data.list);
    setSearched(true);
  };

  const [ hotList, setHotList ] = useState([]);
  const getHotList = async () => {
    const result = await Request.get({
      url: '/doc/question/hotlist'
    });
    if(!result) return;

    setHotList(result.data.list);
  };
  useEffect(() => {
    getHotList();
  }, []);

  return (
    <Page {...{
      ...props,
      rawHeader: true,
      needLogin: false,
      title: '问题与指南'
    }}>
      <section className="doc1-container">
        <div className={`doc1-search-wrapper main-container${searched ? ' searched' : ''}`}>
          <span className="title">碰到问题，搜一下，快速找到答案</span>
          <Input.Search {...{
            value: searchKey,
            placeholder: '请输入问题的关键字',
            allowClear: true,
            enterButton: '搜索',
            size: 'large',
            onChange: e => {
              setSearchKey(e.target.value);
              if(e.target.value == '') {
                setSearched(false);
              }
            },
            onSearch: key => {
              getList({ key });
            }
          }} />
          {
            hotList?.length > 0 && <div className="doc1-hotquestions">
              {
                hotList.map((item, idx) => {
                  return <div className="item" key={idx} onClick={() => {
                    setSearchKey(item.question);
                    getList({ key: item.question });
                  }}>{item.question}</div>
                })
              }
            </div>
          }
        </div>
        {
          searched && <>
            {
              results?.length > 0 && <div className="doc1-results-wrapper main-container">
                {
                  results.map((item, idx) => {
                    return (
                      <div className="doc1-result-item" key={idx} onClick={() => {
                        window.open(`/document/detail?sn=${item.serialNumber}`);
                      }}>
                        <div className="title-wrap">
                          <span className="value">{item.question}</span>
                          <span className="cate"># {DOCUMENT_QUESTION_CATEGORY_LABEL[item.category]}</span>
                        </div>
                        <div className="desc-wrap" dangerouslySetInnerHTML={{ __html: item.content }} />
                      </div>
                    )
                  })
                }
              </div> || <div className="doc1-noresults-wrapper main-container">
                <Result status="404" extra="暂无查询结果" />
              </div>
            }
          </>
        }
      </section>
    </Page>
  );
};

export default PageDocModel;
