/**
 * Created by jimmy on 2019/9/29.
 */
import React, { useState, useEffect } from 'react';
import { Button, Upload, message, Modal} from 'antd';
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import {
	arrayMove,
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
	horizontalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { WarningOutlined, UploadOutlined } from '@ant-design/icons';
import './index.less';

import * as CONF from '../../common/config';
// import * as LOCALE from '../../common/locale';
import * as Utils from '../../common/utils';

const DraggableUploadListItem = ({ originNode, file }) => {
	const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
		id: file.uid,
	});
	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
		cursor: 'move',
	};

	// prevent preview event when drag end
	const className = isDragging
		? 'a' : 'b';
	return (
		<div ref={setNodeRef} style={style} className={className} {...attributes} {...listeners}>
			{/* hide error tooltip when dragging */}
			{file.status === 'error' && isDragging ? originNode.props.children : originNode}
		</div>
	);
};

const CompModel = props => {
	const { fileList = [], defaultFileList = undefined, changeHandle = () => {}, action = '', multiple = false, accept = 'image/*', pictureLength = 8, listType = 'picture-card', buttonText = '上传文件', customUploadButton = null } = props;
	const [ _fileList, _setFileList ] = useState([]);
	const [ _defaultFileList, _setDefaultFileList ] = useState(undefined);
	const initFileList = () => {
		const _fileList = [];
		if(defaultFileList?.length > 0) {
			defaultFileList.map((item, idx) => {
				if(item) {
					const url = item;
					const name = (url => {
						const urlArr = url.split('/');
						return urlArr[urlArr.length - 1];
					})(url);
					_fileList.push({
						url: CONF.imgPrefix + url,
						src: url,
						uid: String(Math.random()) + idx,
						percent: 50,
						name,
						status: 'done'
					});
				}
			});
		}
		_setDefaultFileList(_fileList);
		_setFileList(_fileList);
	};
	useEffect(() => {
		initFileList();
	}, [ defaultFileList ]);

	const uploadButton = customUploadButton || ({
		'picture-card': <div className="picture-card1">
			<UploadOutlined />
			<div style={{ marginTop: 8 }}>{buttonText}</div>
		</div>,
		'picture': <Button icon={<UploadOutlined />}>{buttonText}</Button>
	})[listType];
	const sensor = useSensor(PointerSensor, {
		activationConstraint: {
			distance: 10,
		},
	});

	useEffect(() => {
		const list = [];
		if(_fileList && _fileList.length > 0) {
			_fileList.map(item => {
				if(item && item.src) {
					list.push(item.src);
				}else if(item && item.response && item.response.data && item.response.ok == 0 && item.response.data.src) {
					list.push(item.response.data.src);
				}
			});
		}
		changeHandle && changeHandle(list);
	}, [ _fileList ]);

	if(!_defaultFileList) return null;
	return (
		<DndContext sensors={[sensor]} onDragEnd={({ active, over }) => {
			if (active.id !== over?.id) {
				_setFileList((prev) => {
					const activeIndex = prev.findIndex((i) => i.uid === active.id);
					const overIndex = prev.findIndex((i) => i.uid === over?.id);
					return arrayMove(prev, activeIndex, overIndex);
				});
			}
		}}>
			<SortableContext items={_fileList.map((i) => i.uid)} strategy={verticalListSortingStrategy}>
				<Upload {...{
					multiple,
					accept,
					action,
					listType,
					// defaultFileList: _defaultFileList || undefined,
					maxCount: pictureLength,
					fileList: _fileList || [],
					beforeUpload(file) {
						const flag = Utils.imageUploadSizeVerify(file);
						if(!flag) return Upload.LIST_IGNORE;
						// 只对图片进行压缩
						// if(file && file.type.indexOf('image') > -1) {
						// 	return new Promise(resolve => {
						// 		const reader = new FileReader();
						// 		reader.readAsDataURL(file);
						// 		reader.onload = () => {
						// 			const img = document.createElement('img');
						// 			img.src = reader.result;
						// 			img.onload = () => {
						// 				const canvas = document.createElement('canvas');
						// 				let width = img.naturalWidth;
						// 				let height = img.naturalHeight;
						// 				let ratio = width * height / 180000;
						// 				if(ratio > 1) {
						// 					ratio = Math.sqrt(ratio);
						// 				}else{
						// 					ratio = 1;
						// 				}
						// 				width /= ratio;
						// 				height /= ratio;
						// 				canvas.width = width;
						// 				canvas.height = height;
						//
						// 				const ctx = canvas.getContext('2d');
						// 				ctx.drawImage(img, 0, 0, width, height);
						// 				canvas.toBlob(resolve);
						// 			};
						// 		};
						// 	});
						// }
					},
					onChange: (info, info2) => {
						const { fileList, file } = info;
						if(file?.response?.ok == -1 && file?.response?.msg) {
							message.error(file.response.msg);
							return;
						}
						_setFileList([...fileList]);
					},
					onDrop: (event) => {
						console.log('onDrop', event);
					},
					onRemove: (target) => {
						if(target) {
							return new Promise((resolve, reject) => {
								Modal.confirm({
									title: '删除的数据将无法找回，请慎重操作！',
									icon: <WarningOutlined style={{ color: '#E45357' }} />,
									// content: 'Bla bla ...',
									okText: '确认删除',
									cancelText: '取消',
									onOk: () => {
										const list = _fileList.filter(item => item && item.uid != target.uid);
										_setFileList(list);
										resolve();
									},
									onCancel: () => {
										reject();
									},
								});
							});
						}
					},
					onPreview: (file) => {
						const src = file.src || file?.response?.data?.src;
						window.open(CONF.imgPrefix + src);
					},
					itemRender: (originNode, file) => {
						return <DraggableUploadListItem originNode={originNode} file={file} />
					}
				}}>
					{_fileList.length >= pictureLength ? null : uploadButton}
				</Upload>
			</SortableContext>
		</DndContext>
	)
};

export default CompModel;
