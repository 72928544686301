import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/zh-cn';

import * as Utils from './common/utils';
import './index.less';
import * as serviceWorker from './serviceWorker';
// import asyncComponent from './components/async-component';
// const placeholder = <span style={{ position: 'fixed', width: '100%', height: '100%', display: 'flex', alignItems: 'center', 'justifyContent': 'center' }}>loading……</span>
// const PageHome = asyncComponent(() => import('./pages/home'), placeholder);
// const PageLogin = asyncComponent(() => import('./pages/login'), placeholder);
// const PageResetPassword = asyncComponent(() => import('./pages/resetPassword'), placeholder);
// const PageResult = asyncComponent(() => import('./pages/result'), placeholder);
// const PageApps = asyncComponent(() => import('./pages/apps'), placeholder);
import PageHome from './pages/home';
import PageLogin from './pages/login';
import PageResetPassword from './pages/resetPassword';
import PageResult from './pages/result';
import PageApps from './pages/apps';
import PageDocument from './pages/document';
import PageDocumentDetail from './pages/document/detail';
import PageProtocol from './pages/protocol';
import PagePrice from './pages/price';
import PageEnterpriseCertification from './pages/enterpriseCertification';

const App = () => (
	<Router>
		<Switch>
			<Route exact path="/"><PageHome /></Route>
			<Route exact path="/login"><PageLogin /></Route>
			<Route exact path="/apps"><PageApps /></Route>
			<Route exact path="/password/reset"><PageResetPassword /></Route>
			<Route exact path="/result/:type"><PageResult /></Route>
			<Route exact path="/document"><PageDocument /></Route>
			<Route exact path="/document/detail"><PageDocumentDetail /></Route>
			<Route exact path="/price"><PagePrice /></Route>
			<Route exact path="/protocol/:type"><PageProtocol /></Route>
			<Route exact path="/enterprise/certification"><PageEnterpriseCertification /></Route>

			<Route render={() => (<Redirect to="/result/404" />)}/>
		</Switch>
	</Router>
);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
