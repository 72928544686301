/**
 * Created by jimmy on 2022/6/1.
 */

import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {Tabs, Form, Input, Space, Button, Divider, message, Popover, Popconfirm} from 'antd';
import copyText from 'copy-text-to-clipboard';

import * as REQUEST from "../../common/request";
import * as Utils from "../../common/utils";
import * as config from "../../common/config";
import * as CONST from "../../common/const";
import Page from '../../components/page';
import FButton from '../../components/fbutton';

import { Swiper, SwiperSlide } from "swiper/react";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import "swiper/swiper.less";

import "./index.less";

/**
 * 功能列表
 * @type {[{functions: [{senior: string, super: string, function: string, category: string, base: string}, {senior: string, super: string, function: string, category: string, base: string}], category: string}, {functions: [{senior: string, super: string, function: string, category: string, base: string}, {senior: string, super: string, function: string, category: string, base: string}, {senior: string, super: string, function: string, category: string, base: string}, {senior: string, super: string, function: string, category: string, base: string}, {senior: string, super: string, function: string, category: string, base: string}], category: string}, {functions: [{senior: string, super: string, function: string, category: string, base: string}, {senior: string, super: string, function: string, category: string, base: string}, {senior: string, super: string, function: string, category: string, base: string}], category: string}, {functions: [{senior: string, super: string, function: string, category: string, base: string}], category: string}, {functions: [{senior: string, super: string, function: string, category: string, base: string}, {senior: string, super: string, function: string, category: string, base: string}], category: string}, null, null]}
 */
const functionsList = [
  {
    category: '终端',
    functions: [
      { category: '终端', function: '微信小程序', base: 'Y', senior: 'Y', super: 'Y' },
      { category: '终端', function: 'H5', base: 'Y', senior: 'Y', super: 'Y' },
    ]
  },
  {
    category: '内容',
    functions: [
      { category: '内容', function: '文章数量', base: '50篇', senior: '1000篇', super: '10000篇' },
      { category: '内容', function: '视频数量', base: '10个', senior: '100个', super: '1000个' },
      { category: '内容', function: '分类', base: '20个', senior: '100个', super: '1000个' },
      { category: '内容', function: '文章评论', base: 'Y', senior: 'Y', super: 'Y' },
      { category: '内容', function: '文章点赞', base: 'Y', senior: 'Y', super: 'Y' },
      { category: '内容', function: '文章收藏', base: 'Y', senior: 'Y', super: 'Y' },
    ]
  },
  {
    category: '平台搭建',
    functions: [
      { category: '平台搭建', function: '页面搭建数量', base: '20个', senior: '100个', super: '1000个' },
      { category: '平台搭建', function: '分享配置', base: 'X', senior: 'Y', super: 'Y' },
      { category: '平台搭建', function: 'h5自定义域名', base: 'X', senior: 'Y', super: 'Y' },
    ]
  },
  {
    category: '数据服务',
    functions: [
      { category: '数据服务', function: '数据分析', base: 'Y', senior: 'Y', super: 'Y' },
    ]
  },
  {
    category: '账号/资源',
    functions: [
      { category: '账号/资源', function: '员工账号', base: '1个', senior: '5个', super: '20个' },
      { category: '账号/资源', function: '组织架构', base: 'X', senior: 'Y', super: 'Y' },
      { category: '账号/资源', function: '云服务资源', base: '5G', senior: '10G', super: '50G' },
    ]
  },
  {
    category: '安全保障',
    functions: [
      { category: '安全保障', function: '全站加密访问', base: 'Y', senior: 'Y', super: 'Y' },
      { category: '安全保障', function: '独立空间', base: 'Y', senior: 'Y', super: 'Y' },
      { category: '安全保障', function: '多节点CDN', base: 'Y', senior: 'Y', super: 'Y' },
    ]
  },
  {
    category: '贴心服务',
    functions: [
      { category: '贴心服务', function: '在线客服', base: 'Y', senior: 'Y', super: 'Y' },
      { category: '贴心服务', function: '免费无代码搭建培训', base: 'Y', senior: 'Y', super: 'Y' },
      { category: '贴心服务', function: 'H5域名代理注册', base: 'Y', senior: 'Y', super: 'Y' },
      { category: '贴心服务', function: '微信小程序代理注册', base: 'Y', senior: 'Y', super: 'Y' },
    ]
  }
];
const functionSign = {
  X: <CloseOutlined style={{ color: '#FF0000', fontWeight: '900' }} />,
  Y: <CheckOutlined style={{ color: '#00b959', fontWeight: '900' }} />,
}

const PagePriceModel = props => {
  const history = useHistory();

  const [ userInfo, setUserInfo ] = useState(null);
  const getUserInfo = async () => {
    const result = await REQUEST.get({
      url: '/account/get',
      keepStatus: true
    });
    if(!result) return;
    setUserInfo(result.data || {});
  };
  useEffect(() => {
    getUserInfo();

    return () => false;
  }, []);

  const [ pkgList, setPkgList ] = useState([]);
  const getPkgList = async () => {
    const result = await REQUEST.get({
      url: '/app/package/list'
    });
    if(!result) return;

    setPkgList(result.data.list);
  };
  useEffect(() => {
    getPkgList();
  }, []);

  return (
    <Page title="产品介绍">
      <section className="price-container">
        <section className="title-wrap">
          <span className="large">人人可以搭建移动应用</span>
          <span>移动应用无代码搭建平台，无需一行代码搭建属于你的移动应用</span>
          <span>一次配置，同时支持 H5、微信小程序</span>
        </section>
        <section className="price-list-wrap main-container">
          {
            pkgList?.length > 0 && pkgList.map((item, idx) => {
              return (
                <div className="price-list" key={idx}>
                  <div className="title">{item.name}</div>
                  <div className="desc">{item.description}</div>
                  <div className="price">¥<span>{item.price}.00</span>/月</div>
                  <div className="subscribe-btn" onClick={() => {
                    if(userInfo?.id) {
                      history.push('/apps');
                    }else{
                      history.push('/login');
                    }
                  }}>立即订阅</div>

                  <div className="funcs">
                    {
                      functionsList.map((fitem, fidx) => {
                        return (
                          <div className="func-block">
                            <span>{fitem.category}</span>
                            <div className="func-list">
                              {
                                fitem.functions.map((_fitem, _fidx) => {
                                  const base = _fitem[item.level];
                                  return (
                                    <div className="fun-item" key={_fidx}>
                                      <label>{_fitem.function}</label>
                                      <span>{['Y', 'X'].includes(base) ? functionSign[base] : base}</span>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              )
            })
          }
        </section>
      </section>
    </Page>
  );
};

export default PagePriceModel;
