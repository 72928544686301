/**
 * 用户账户交互类型
 * @type {number}
 */
export const ACCOUNT_ACTION_TYPE_LOGIN = 1;
export const ACCOUNT_ACTION_TYPE_REGISTER = 2;
export const ACCOUNT_ACTION_TYPE_LABEL = {
  [ACCOUNT_ACTION_TYPE_LOGIN]: '登录',
  [ACCOUNT_ACTION_TYPE_REGISTER]: '注册',
}

export const HEADER_MODE_DEFAULT = 1;
export const HEADER_MODE_ACCOUNT = 2;
export const HEADER_MODE_RAW = 3;
export const HEADER_MODE_LABEL = {
  [HEADER_MODE_DEFAULT]: '默认模式',
  [HEADER_MODE_ACCOUNT]: '用户模式',
  [HEADER_MODE_RAW]: '简约模式'
}


/**
 * 店铺状态
 */
export const APP_STATUS_INIT = 1;
export const APP_STATUS_ONLINE = 2;
export const APP_STATUS_EXPIRED = 3;
export const APP_STATUS_ABNORMAL = 4;
export const APP_STATUS_LABEL = {
  [APP_STATUS_INIT]: '初始化',
  [APP_STATUS_ONLINE]: '在线',
  [APP_STATUS_EXPIRED]: '过期',
  [APP_STATUS_ABNORMAL]: '异常'
};

/**
 * 是否
 * @type {number}
 */
export const BOOL_IS = 1;
export const BOOL_NOT = 2;
export const BOOL_LABEL = {
  [BOOL_IS]: '是',
  [BOOL_NOT]: '否',
};


/**
 * 主体类型
 * 1 - 企业；2 - 个体户；3 - 政府及事业单位；4 - 其他组织；5 - 个人
 */
export const SUBJECT_TYPE_ENTERPRISE = 1;
export const SUBJECT_TYPE_SELF_EMPLOYED = 2;
export const SUBJECT_TYPE_GOV_INS = 3;
export const SUBJECT_TYPE_OTHER_GROUPS = 4;
export const SUBJECT_TYPE_PERSON = 5;
export const SUBJECT_TYPE_LABEL = {
  [SUBJECT_TYPE_ENTERPRISE]: '企业',
  [SUBJECT_TYPE_SELF_EMPLOYED]: '个体户',
  [SUBJECT_TYPE_GOV_INS]: '政府及事业单位',
  [SUBJECT_TYPE_OTHER_GROUPS]: '其他组织',
  [SUBJECT_TYPE_PERSON]: '个人',
};

/**
 * 证件类型
 * 1 - 大陆身份证；2 - 护照（限境外人士）；3 - 来往内地通行证（中国香港居民）；4 - 来往内地通行证（中国澳门居民）；5 - 来往大陆通行证（中国台湾居民）
 */
export const CERTIFICATION_TYPE_IDCARD = 1;
export const CERTIFICATION_TYPE_PASSWORDPORT = 2;
export const CERTIFICATION_TYPE_PASS_HK = 3;
export const CERTIFICATION_TYPE_PASS_MACAO = 4;
export const CERTIFICATION_TYPE_PASS_TAIWAN = 5;
export const CERTIFICATION_TYPE_LABEL = {
  [CERTIFICATION_TYPE_IDCARD]: '大陆身份证',
  [CERTIFICATION_TYPE_PASSWORDPORT]: '护照（限境外人士）',
  [CERTIFICATION_TYPE_PASS_HK]: '来往内地通行证（中国香港居民）',
  [CERTIFICATION_TYPE_PASS_MACAO]: '来往内地通行证（中国澳门居民）',
  [CERTIFICATION_TYPE_PASS_TAIWAN]: '来往大陆通行证（中国台湾居民）',
};

/**
 * 企业认证实际操作人
 * 1 - 法人；2 - 代办人
 */
export const ENTERPRISE_REAL_OPERATOR_LEGAL = 1;
export const ENTERPRISE_REAL_OPERATOR_AGENT = 2;
export const ENTERPRISE_REAL_OPERATOR_LABEL = {
  [ENTERPRISE_REAL_OPERATOR_LEGAL]: '法人',
  [ENTERPRISE_REAL_OPERATOR_AGENT]: '代办人'
};

/**
 * 企业认证状态
 * 1 - 审核中；2 - 通过；3 - 拒绝；4 - 重新审核中；5 - 取消认证
 */
export const ENTERPRISE_CERTIFICATION_STATUS_REVIEWING = 1;
export const ENTERPRISE_CERTIFICATION_STATUS_PASSED = 2;
export const ENTERPRISE_CERTIFICATION_STATUS_REJECTED = 3;
export const ENTERPRISE_CERTIFICATION_STATUS_REREVIEWING = 4;
export const ENTERPRISE_CERTIFICATION_STATUS_CANCELLED = 5;
export const ENTERPRISE_CERTIFICATION_STATUS_LABEL = {
  [ENTERPRISE_CERTIFICATION_STATUS_REVIEWING]: '认证中',
  [ENTERPRISE_CERTIFICATION_STATUS_PASSED]: '已认证',
  [ENTERPRISE_CERTIFICATION_STATUS_REJECTED]: '已拒绝',
  [ENTERPRISE_CERTIFICATION_STATUS_REREVIEWING]: '认证中',
  [ENTERPRISE_CERTIFICATION_STATUS_CANCELLED]: '已取消',
};

/**
 * 问题分类
 * 1 - 平台初识；2 - 应用搭建；3 - 商城装修；4 - 微信小程序；5 - 组织架构；6 - H5
 */
export const DOCUMENT_QUESTION_CATEGORY_HELLO_PLATFORM = 1;
export const DOCUMENT_QUESTION_CATEGORY_APP_BUILD = 2;
export const DOCUMENT_QUESTION_CATEGORY_APP_FURNISH = 3;
export const DOCUMENT_QUESTION_CATEGORY_WX_MINI_PROGRAM = 4;
export const DOCUMENT_QUESTION_CATEGORY_ORGANIZATION = 5;
export const DOCUMENT_QUESTION_CATEGORY_H5 = 6;
export const DOCUMENT_QUESTION_CATEGORY_LABEL = {
  [DOCUMENT_QUESTION_CATEGORY_HELLO_PLATFORM]: '平台初识',
  [DOCUMENT_QUESTION_CATEGORY_APP_BUILD]: '应用搭建',
  [DOCUMENT_QUESTION_CATEGORY_APP_FURNISH]: '商城装修',
  [DOCUMENT_QUESTION_CATEGORY_WX_MINI_PROGRAM]: '微信小程序',
  [DOCUMENT_QUESTION_CATEGORY_H5]: 'H5',
  [DOCUMENT_QUESTION_CATEGORY_ORGANIZATION]: '组织架构',
};
