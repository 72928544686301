/**
 * Created by jimmy on 2022/6/1.
 */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {Result, Button, Steps, Input, Form, Radio, message,Space} from 'antd';

import '../index.less';
import * as REQUEST from "../../../common/request";
import * as Utils from "../../../common/utils";

const CompCertStep0Model = props => {
  const history = useHistory();

  const [ second, setSecond ] = useState();
  const getCode = async () => {
    const result = await REQUEST.get({
      url: '/enterprise/certification/code/get'
    });
    if(!result) return;

    message.success('验证码发送成功');
    Utils.countdown(60, second => {
      setSecond(second);
    }).start();
  };

  return (
    <div style={props.style}>
      <Form.Item label="请输入152****5660手机号上的短信验证码，完成身份验证。" name="mobileCode" required>
        <Space.Compact {...{
          style: { width: '100%' }
        }}>
          <Input placeholder="请输入" />
          <Button type="primary" onClick={getCode}>{ second > 0 && `${second}s重新获取` || '获取验证码'}</Button>
        </Space.Compact>

        {/*<Input placeholder="请输入" onChange={e => console.log(e.currentTarget.value)} />*/}
      </Form.Item>

      <Form.Item label=" " colon={false}>
        <Button type="primary" onClick={() => {
          if(typeof props?.handleChange == 'function') {
            props.handleChange(1);
          }
        }}>下一步</Button>
      </Form.Item>
    </div>
  );
};

export default CompCertStep0Model;
